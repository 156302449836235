export function isValidRegExp(pattern: string) {
  try {
    // Ignore inline regex flags prefix
    // i.e. `(?a)`, `(?i)`, `(?L)`, `(?m)`, `(?s)`, `(?u)` and `(?x)`
    const patternWithoutInlineFlag = pattern.replace(/\(\?[aiLmsux]+\)/, "");

    new RegExp(patternWithoutInlineFlag);
    return true;
  } catch {
    return false;
  }
}
