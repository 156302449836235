import { IconButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";

import { useToast } from "../../hooks/toast";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";

type Props = {
  onSubmit: (message: string) => void;
};

export function useSupportRequestPopupHandle(props: Props) {
  const { onSubmit } = props;

  const [isOpened, setIsOpened] = React.useState(false);
  const close = React.useCallback(() => {
    setIsOpened(false);
  }, []);
  const open = React.useCallback(() => {
    setIsOpened(true);
  }, []);

  const triggerProps = React.useMemo(
    () => ({
      isOpened,
      close,
      onSubmit,
    }),
    [isOpened, close, onSubmit]
  );

  return React.useMemo(
    () => ({
      triggerProps,
      open,
    }),
    [triggerProps, open]
  );
}

export function SupportRequestPopup(
  props: ReturnType<typeof useSupportRequestPopupHandle>["triggerProps"]
) {
  const { isOpened, close, onSubmit } = props;
  const [text, setText] = React.useState("");

  const opacity = isOpened ? "opacity-100" : "opacity-0";
  const toast = useToast();

  const submit = React.useCallback(() => {
    onSubmit(text);
    toast.success("manage_fields_guidance.support_request_popup.toast");
    close();
  }, [text, onSubmit, close, toast]);

  const onTextChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setText(newValue ?? "");
    },
    []
  );

  return (
    <div
      className={cntl`card fixed right-[24px] bottom-[24px] w-[360px] h-[220px] p-[24px]
            flex flex-col z-50
            transition-opacity duration-500 ${opacity} ${
        isOpened ? null : "hidden"
      }`}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="truncate text-[#242424] leading-[20px] text-[20px] font-semibold h-[24px]">
          <FormattedMessage id="manage_fields_guidance.support_request_popup.title" />
        </div>
        <IconButton
          onClick={close}
          iconProps={{
            iconName: "ChromeClose",
          }}
          styles={{
            root: {
              width: 28,
              height: 32,
            },
            icon: {
              color: "#000",
              fontSize: 12,
            },
          }}
        />
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <div className="text-[#605E5C] leading-[20px] text-[14px] font-normal">
          <FormattedMessage id="manage_fields_guidance.support_request_popup.message" />
        </div>
        <div>
          <TextField
            onChange={onTextChange}
            labelId=""
            resizable={false}
            multiline={true}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end items-center gap-[8px]">
        <PrimaryButton textId="common.submit" onClick={submit} />
      </div>
    </div>
  );
}
