import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { UpdateFormGroupRequestParams } from "../apiClient/mixin/formGroup";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { ExtractorSettings } from "../types/extractorSettings";
import {
  BriefFormGroup,
  DetailFormGroup,
  FormGroupType,
} from "../types/formGroup";
import { triggerFileSave } from "../utils/file";
import { useConfirmModalActionCreator } from "./confirmModal";
import { FormsInvalidated } from "./form";
import { useResourceOwnerActionCreator } from "./resourceOwner";

export const FormGroupCreated = createAction<BriefFormGroup>(
  "formGroup/formGroupCreated"
);

export const FormGroupDeleted = createAction(
  "formGroup/formGroupDeleted",
  (formGroupId: string) => ({ payload: { formGroupId } })
);

export const FormGroupPinned = createAction(
  "formGroup/formGroupPinned",
  (formGroupId: string) => ({ payload: { formGroupId, value: true } })
);

export const FormGroupUnpinned = createAction(
  "formGroup/formGroupUnpinned",
  (formGroupId: string) => ({ payload: { formGroupId, value: false } })
);

export const GotFormGroup = createAction<DetailFormGroup>(
  "formGroup/gotFormGroup"
);

export const FormGroupUpdated = createAction<DetailFormGroup>(
  "formGroup/FormGroupUpdated"
);

export const FormGroupsInvalidated = createAction(
  "formGroup/formGroupsInvalidated"
);

export type FormGroupAction =
  | ReturnType<typeof FormGroupCreated>
  | ReturnType<typeof FormGroupDeleted>
  | ReturnType<typeof GotFormGroup>
  | ReturnType<typeof FormGroupsInvalidated>;

export function useFormGroupActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { selectTeam } = useResourceOwnerActionCreator();

  const { pushCreatedExtractorEvent } = useGtm();

  const createFormGroup = useCallback(
    async (name: string, type: FormGroupType): Promise<BriefFormGroup> => {
      const { resourceOwnerId } = getState().resourceOwner;
      const formGroup = await apiClient.createFormGroup(
        name,
        type,
        resourceOwnerId
      );

      pushCreatedExtractorEvent(formGroup.id, name, "combined", "combine");

      dispatch(FormGroupCreated(formGroup));

      return formGroup;
    },
    [dispatch, getState, pushCreatedExtractorEvent]
  );

  const deleteFormGroup = useCallback(
    async (formGroupId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.deleteFormGroup(formGroupId, resourceOwnerId);

      dispatch(FormGroupDeleted(formGroupId));
    },
    [dispatch, getState]
  );

  const pinFormGroup = useCallback(
    async (formGroupId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.pinFormGroup(formGroupId, resourceOwnerId);

      dispatch(FormGroupPinned(formGroupId));
    },
    [dispatch, getState]
  );
  const unpinFormGroup = useCallback(
    async (formGroupId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.unpinFormGroup(formGroupId, resourceOwnerId);

      dispatch(FormGroupUnpinned(formGroupId));
    },
    [dispatch, getState]
  );

  const getFormGroup = useCallback(
    async (formGroupId: string): Promise<DetailFormGroup> => {
      const { resourceOwnerId } = getState().resourceOwner;
      const formGroup = await apiClient.getFormGroup(
        formGroupId,
        resourceOwnerId
      );

      if (formGroup.resourceOwnerId) {
        selectTeam(formGroup.resourceOwnerId);
      }

      dispatch(GotFormGroup(formGroup));

      return formGroup;
    },
    [dispatch, getState, selectTeam]
  );

  const { handleConflict } = useConfirmModalActionCreator();
  const updateFormGroup = useCallback(
    async (
      formGroupId: string,
      params: Omit<UpdateFormGroupRequestParams, "retrievedAt">,
      retrievedAt: string
    ): Promise<DetailFormGroup> => {
      const { resourceOwnerId } = getState().resourceOwner;
      const formGroup = await handleConflict(
        async () => {
          return await apiClient.updateFormGroup(
            formGroupId,
            {
              ...params,
              // if retrievedAt is undef, api will not check conflict
              retrievedAt: retrievedAt,
            },
            resourceOwnerId
          );
        },
        async () => {
          return await apiClient.updateFormGroup(
            formGroupId,
            {
              ...params,
              // if retrievedAt is undef, api will overwrite
              retrievedAt: undefined,
            },
            resourceOwnerId
          );
        },
        {
          titleId: "form_group.modifed_prompt.title",
          messageId: "form_group.modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      dispatch(FormGroupUpdated(formGroup));

      return formGroup;
    },
    [dispatch, getState, handleConflict]
  );

  const updateFormGroupExtractorSettings = useCallback(
    async (settings: ExtractorSettings): Promise<DetailFormGroup> => {
      const { currentFormGroup } = getState().formGroup;
      if (currentFormGroup == null) {
        throw new Error("should not happen");
      }
      return await updateFormGroup(
        currentFormGroup.id,
        {
          name: settings.extractorName,
          config: {
            ...currentFormGroup.config,
            post_process_script: settings.postProcessingScript,
            transform_response_script: settings.transformResponseScript,
            ocr_config: settings.ocrConfig,
            processing_mode: settings.processingMode,
            image_quality_config: {
              ...(currentFormGroup.config.image_quality_config ?? {}),
              enabled: settings.imageQualityEnabled,
            },
          },
        },
        currentFormGroup.updatedAt
      );
    },
    [getState, updateFormGroup]
  );

  const importFormGroup = useCallback(
    async (file: File, useExistingForms: boolean) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const url = apiClient.getEffectiveEndpoint("/import-form-group");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("use_existing_forms", useExistingForms ? "1" : "0");
      if (resourceOwnerId) {
        formData.append("resource_owner_id", resourceOwnerId);
      }

      const response = await apiClient.fetch(url, {
        method: "POST",
        body: formData,
      });

      const { result } = await response.json();

      if (!result || result.status !== "ok") {
        throw result;
      }

      dispatch(FormGroupsInvalidated());
      dispatch(FormsInvalidated());

      return result;
    },
    [dispatch, getState]
  );

  const exportFormGroup = useCallback(
    async (
      formGroupId: string,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> => {
      const path =
        `/export-form-group?form_group_id=${formGroupId}` +
        (resourceOwnerId ? `&resource_owner_id=${resourceOwnerId}` : "");
      const url = apiClient.getEffectiveEndpoint(path, region);
      const response = await apiClient.fetch(url);
      await triggerFileSave(response);
    },
    []
  );

  return useMemo(
    () => ({
      createFormGroup,
      getFormGroup,
      deleteFormGroup,
      pinFormGroup,
      unpinFormGroup,
      updateFormGroup,
      updateFormGroupExtractorSettings,
      importFormGroup,
      exportFormGroup,
    }),
    [
      createFormGroup,
      getFormGroup,
      deleteFormGroup,
      pinFormGroup,
      unpinFormGroup,
      updateFormGroup,
      updateFormGroupExtractorSettings,
      importFormGroup,
      exportFormGroup,
    ]
  );
}
