import { LocalizerValue } from "./models";

export class FOCRError {
  messageId: string;
  detail?: LocalizerValue;

  constructor(messageId: string, detail?: LocalizerValue) {
    this.messageId = messageId;
    this.detail = detail;
  }
}

export class UnmappedFOCRError extends FOCRError {
  constructor(serverMessage: string) {
    super("error.unmapped_error", {
      message: serverMessage,
    });
  }
}

export enum ApiError {
  UnexpectedError = 100,
  NotAuthenticated = 101,
  PasswordIsWeak = 201,
  InvalidCredential = 202,
  AuthIdentityAlreadyExists = 203,
  PermissionDenied = 204,
  PasswordIsSame = 205,
  PasswordIsReused = 206,
  AccountLocked = 207,
  PasswordExpired = 208,
  UserNotFound = 2001,
  UserAlreadyExists = 2008,
  ConflictFound = 3005,
  DeleteFallbackFormFailure = 3006,
  DeleteFormConnectedToWorkspace = 3008,
  AnchorNotFound = 4001,
  FieldNotFound = 5001,
  DetectionRegionNotFound = 7001,
  DeleteFormGroupConnectedToWorkspace = 10003,
  CustomModelNotFound = 11001,
  DeleteCustomModelConnectedToWorkspace = 11005,
  TeamUserAlreadyExists = 13002,
  TeamNotFound = 13003,
  TeamLookupIDAlreadyExist = 13005,
  TeamInvitationNotFound = 15001,
  TeamInvitationAlreadyExists = 15002,
  TeamInvitationEmailNotMatch = 15003,
  TeamInvitationInvalid = 15004,
  TeamInvitationPendingLimitExcetted = 15005,
  WorkspaceNotFound = 22001,
  SharepointSubscriptionFailedToCreate = 23002,
  SharepointSubscriptionFolderNotFound = 23003,
  SharepointSubscriptionAlreadyExists = 23004,
  ExtractionNotFound = 24001,
  ExtractionResultNotFound = 24002,
  ImportArchiveTypeMismatched = 25001,
  ConfigSnapshotNotFound = 27001,
  ConfigSnapshotAlreadyBookmarked = 27002,
}

export enum WorkerError {
  InvalidToken = 1001,
  FormIdNotFound = 1002,
  FormImageURLNotFound = 1003,
  CannotLoadImage = 1004,
  UploadedFileTooLarge = 1005,
  ErrorPaymentRequired = 1006,
  TooManyRequest = 1007,
  UsageReachedHardLimit = 1008,
  ImageDimensionExceedLimit = 1009,
  FormNotFound = 2001,
  FormNotReady = 2002,
  FormUnmatchedQueryImage = 2003,
  ErrorDuringExtractingFormInfo = 2004,
  FormGroupNotFound = 2005,
  FormGroupIsEmpty = 2006,
  CustomModelNotReady = 2009,
  LLMError = 2010,
  LLMTokenLimitExceeded = 2011,
  CannotUseWorkspaceWithoutAsync = 2012,
  WorkspaceNotFound = 2013,
  WorkspaceNotLinkedToExtractor = 2014,
  ImageQualityCheckFailed = 2021,
  ReceiptGroupIdNotFound = 3001,
  GoogleCloudVisionServiceAccountKeyIsNotSet = 3002,
  ErrorDuringExtractingReceiptInfo = 3003,
  ErrorDuringAccessingGoogleVisionAPI = 3004,
  AzureComputerVisionSettingIsNotSet = 3005,
}

export enum PluginError {
  ReceiptGroupReachedLimit = 6002,
  StripeCardError = 9003,
  StripeError = 9004,
}

export enum AuditLogError {
  AuditLogNotFound = 18001,
}

const errors = {
  DuplicatedUser: new FOCRError("error.duplicated_user"),
  UserNotFound: new FOCRError("error.user_not_found"),
  UnknownError: new FOCRError("error.unknown_error"),
  IncorrectPassword: new FOCRError("error.incorrect_password"),
  IncorrectEmailOrPassword: new FOCRError("error.incorrect_email_or_password"),
  CannotGetImageDimension: new FOCRError("error.cannot_get_image_dimension"),
  FormImageSizeTooLarge: new FOCRError("error.form_image_size_too_large"),
  PasswordTooSimple: new FOCRError("error.password_too_simple"),
  PasswordIsSame: new FOCRError("error.password_is_same"),
  PasswordIsReused: new FOCRError("error.password_is_reused"),
  AccountLocked: new FOCRError("error.account_locked"),
  PasswordExpired: new FOCRError("error.password_expired"),
  ConflictFound: new FOCRError("error.conflict_found"),
  InvalidToken: new FOCRError("error.invalid_token"),
  FormIdNotFound: new FOCRError("error.form_id_not_found"),
  FormImageURLNotFound: new FOCRError("error.form_compute_feature_no_image"),
  CannotLoadImage: new FOCRError("error.cannot_load_image"),
  ImageDimensionExceedLimit: new FOCRError(
    "error.image_dimension_exceed_limit"
  ),
  UploadedFileTooLarge: new FOCRError("error.uploaded_file_too_large"),
  FormNotFound: new FOCRError("error.form_not_found"),
  FormNotReady: new FOCRError("error.form_not_ready"),
  FormUnmatchedQueryImage: new FOCRError("error.form_unmatched_query_image"),
  ExtractionTimeout: new FOCRError("error.extraction_timeout"),
  ExportTimeout: new FOCRError("error.export_timeout"),
  ErrorDuringExtractingFormInfo: new FOCRError(
    "error.error_during_extracting_form_info"
  ),
  FormGroupNotFound: new FOCRError("error.form_group_not_found"),
  FormGroupIsEmpty: new FOCRError("error.form_group_is_empty"),
  CustomModelNotReady: new FOCRError("error.custom_model_not_ready"),
  ReceiptGroupIdNotFound: new FOCRError("error.receipt_group_id_not_found"),
  GoogleCloudVisionServiceAccountKeyIsNotSet: new FOCRError(
    "error.google_service_account_key_is_not_set"
  ),
  ErrorDuringExtractingReceiptInfo: new FOCRError(
    "error.error_during_extract_receipt_info"
  ),
  ErrorDuringAccessingGoogleVisionAPI: new FOCRError(
    "error.error_during_accessing_google_vision_api"
  ),
  AzureComputerVisionSettingIsNotSet: new FOCRError(
    "error.azure_computer_vision_setting_is_not_set"
  ),
  ErrorPaymentRequired: new FOCRError("error.payment.required"),
  TooManyRequest: new FOCRError("error.too_many_request"),
  UsageReachedHardLimit: new FOCRError("error.usage_reached_hard_limit"),
  AnchorNotFound: new FOCRError("error.anchor_not_found"),
  FieldNotFound: new FOCRError("error.field_not_found"),
  DetectionRegionNotFound: new FOCRError("error.detection_region_not_found"),
  DeleteFallbackFormFailure: new FOCRError(
    "error.delete_fallback_form_failure"
  ),
  CreatePaymentMethodFailure: new FOCRError(
    "error.create_payment_method_failure"
  ),
  StripeKeyIsEmpty: new FOCRError("error.stripe.key.empty"),
  StripeLoadFailure: new FOCRError("error.stripe.load.failure"),

  StripeError: new FOCRError("error.stripe.error"),

  StripeCardProcessingError: new FOCRError(
    "errors.stripe.card.processing_error"
  ),
  StripeCardDeclinedError: new FOCRError("errors.stripe.card.card_declined"),
  StripeCardIncorrectCvcError: new FOCRError(
    "errors.stripe.card.incorrect_cvc"
  ),
  StripeCardExpiredError: new FOCRError("errors.stripe.card.expired_card"),

  UnexpectedServerResponseError: new FOCRError(
    "error.unexpected_server_response"
  ),

  TeamUserAlreadyExists: new FOCRError("error.invite.team_user_already_exists"),
  TeamLookupIDAlreadyExists: new FOCRError(
    "error.team.lookup_id_already_exists"
  ),
  TeamInvitationAlreadyExists: new FOCRError(
    "error.invite.invitation_already_exists"
  ),
  TeamInvitationEmailNotMatch: new FOCRError(
    "error.invite.invitation_email_not_match"
  ),
  TeamInvitationInvalid: new FOCRError("error.invite.invalid_invitation"),
  TeamInvitationNotFound: new FOCRError("error.invite.not_found"),
  TeamInvitationPendingLimitExcetted: new FOCRError(
    "error.invite.pending_limit_exceeded"
  ),
  UserAlreadyExists: new FOCRError("error.user_already_exists"),
  ConfirmationRejected: new FOCRError("error.user_rejected_confirmation"),
  ConfigSnapshotNotFound: new FOCRError("error.config_snapshot_not_found"),
  ConfigSnapshotAlreadyBookmarked: new FOCRError(
    "error.config_snapshot_already_bookmarked"
  ),
  AuditLogNotFound: new FOCRError("error.audit_log.not_found"),
  CannotRenderImage: new FOCRError("error.cannot_render_image"),
  TeamNotFound: new FOCRError("error.team.not_found"),
  CustomModelNotFound: new FOCRError("error.custom_model.not_found"),
  PermissionDenied: new FOCRError("error.permission_denied"),
  NotAuthenticated: new FOCRError("error.not_authenticated"),
  LLMError: new FOCRError("error.llm_error"),
  LLMTokenLimitExceeded: new FOCRError("error.llm_token_limit_exceeded"),
  CannotDeleteExtractorConnectedToWorkspace: new FOCRError(
    "error.cannot_delete_extractor_connected_to_workspace"
  ),
  CancelledByUser: new FOCRError("error.cancelled_by_user"),
  ExtractionNotFound: new FOCRError("error.extraction.not_found"),
  ExtractionResultNotFound: new FOCRError("error.extraction.result_not_found"),
  CannotUseWorkspaceWithoutAsync: new FOCRError(
    "error.cannot_use_workspace_without_async"
  ),
  WorkspaceNotFound: new FOCRError("error.workspace_not_found"),
  WorkspaceNotLinkedToExtractor: new FOCRError(
    "error.workspace_not_linked_to_extractor"
  ),
  ImageQualityCheckFailed: new FOCRError("error.image_quality_check_failed"),
  SharepointSubscriptionFailedToCreate: new FOCRError(
    "error.sharepoint_subscription_failed_to_create"
  ),
  SharepointSubscriptionFolderNotFound: new FOCRError(
    "error.sharepoint_subscription_folder_not_found"
  ),
  SharepointSubscriptionAlreadyExists: new FOCRError(
    "error.sharepoint_subscription_already_exists"
  ),
  ImportArchiveTypeMismatched: new FOCRError(
    "error.import_archive_type_mismatched"
  ),
  NetworkError: new FOCRError("error.network_error"),
};

export const WorkerErrorMap: { [key: number]: FOCRError } = {
  [WorkerError.InvalidToken]: errors.InvalidToken,
  [WorkerError.FormIdNotFound]: errors.FormIdNotFound,
  [WorkerError.FormImageURLNotFound]: errors.FormImageURLNotFound,
  [WorkerError.ImageDimensionExceedLimit]: errors.ImageDimensionExceedLimit,
  [WorkerError.CannotLoadImage]: errors.CannotLoadImage,
  [WorkerError.UploadedFileTooLarge]: errors.UploadedFileTooLarge,
  [WorkerError.FormNotFound]: errors.FormNotFound,
  [WorkerError.FormNotReady]: errors.FormNotReady,
  [WorkerError.FormUnmatchedQueryImage]: errors.FormUnmatchedQueryImage,
  [WorkerError.ErrorDuringExtractingFormInfo]:
    errors.ErrorDuringExtractingFormInfo,
  [WorkerError.FormGroupNotFound]: errors.FormGroupNotFound,
  [WorkerError.CustomModelNotReady]: errors.CustomModelNotReady,
  [WorkerError.FormGroupIsEmpty]: errors.FormGroupIsEmpty,
  [WorkerError.ReceiptGroupIdNotFound]: errors.ReceiptGroupIdNotFound,
  [WorkerError.GoogleCloudVisionServiceAccountKeyIsNotSet]:
    errors.GoogleCloudVisionServiceAccountKeyIsNotSet,
  [WorkerError.ErrorDuringExtractingReceiptInfo]:
    errors.ErrorDuringExtractingReceiptInfo,
  [WorkerError.ErrorDuringAccessingGoogleVisionAPI]:
    errors.ErrorDuringAccessingGoogleVisionAPI,
  [WorkerError.AzureComputerVisionSettingIsNotSet]:
    errors.AzureComputerVisionSettingIsNotSet,
  [WorkerError.ErrorPaymentRequired]: errors.ErrorPaymentRequired,
  [WorkerError.TooManyRequest]: errors.TooManyRequest,
  [WorkerError.UsageReachedHardLimit]: errors.UsageReachedHardLimit,
  [WorkerError.LLMError]: errors.LLMError,
  [WorkerError.LLMTokenLimitExceeded]: errors.LLMTokenLimitExceeded,
  [WorkerError.CannotUseWorkspaceWithoutAsync]:
    errors.CannotUseWorkspaceWithoutAsync,
  [WorkerError.WorkspaceNotFound]: errors.WorkspaceNotFound,
  [WorkerError.WorkspaceNotLinkedToExtractor]:
    errors.WorkspaceNotLinkedToExtractor,
  [WorkerError.ImageQualityCheckFailed]: errors.ImageQualityCheckFailed,
};

export default errors;

export const ApiErrorMap: { [key in ApiError]?: FOCRError } = {
  [ApiError.AuthIdentityAlreadyExists]: errors.DuplicatedUser,
  [ApiError.InvalidCredential]: errors.IncorrectEmailOrPassword,
  [ApiError.PasswordIsWeak]: errors.PasswordTooSimple,
  [ApiError.PasswordIsSame]: errors.PasswordIsSame,
  [ApiError.PasswordIsReused]: errors.PasswordIsReused,
  [ApiError.AccountLocked]: errors.AccountLocked,
  [ApiError.PasswordExpired]: errors.PasswordExpired,
  [ApiError.UnexpectedError]: errors.UnknownError,
  [ApiError.TeamUserAlreadyExists]: errors.TeamUserAlreadyExists,
  [ApiError.TeamLookupIDAlreadyExist]: errors.TeamLookupIDAlreadyExists,
  [ApiError.TeamInvitationAlreadyExists]: errors.TeamInvitationAlreadyExists,
  [ApiError.TeamInvitationEmailNotMatch]: errors.TeamInvitationEmailNotMatch,
  [ApiError.TeamInvitationInvalid]: errors.TeamInvitationInvalid,
  [ApiError.TeamInvitationNotFound]: errors.TeamInvitationNotFound,
  [ApiError.TeamInvitationPendingLimitExcetted]:
    errors.TeamInvitationPendingLimitExcetted,
  [ApiError.ConflictFound]: errors.ConflictFound,
  [ApiError.AnchorNotFound]: errors.AnchorNotFound,
  [ApiError.FieldNotFound]: errors.FieldNotFound,
  [ApiError.DetectionRegionNotFound]: errors.DetectionRegionNotFound,
  [ApiError.DeleteFallbackFormFailure]: errors.DeleteFallbackFormFailure,
  [ApiError.UserNotFound]: errors.UserNotFound,
  [ApiError.UserAlreadyExists]: errors.UserAlreadyExists,
  [ApiError.TeamNotFound]: errors.TeamNotFound,
  [ApiError.CustomModelNotFound]: errors.CustomModelNotFound,
  [ApiError.PermissionDenied]: errors.PermissionDenied,
  [ApiError.NotAuthenticated]: errors.NotAuthenticated,
  [ApiError.DeleteFormConnectedToWorkspace]:
    errors.CannotDeleteExtractorConnectedToWorkspace,
  [ApiError.DeleteFormGroupConnectedToWorkspace]:
    errors.CannotDeleteExtractorConnectedToWorkspace,
  [ApiError.ExtractionNotFound]: errors.ExtractionNotFound,
  [ApiError.SharepointSubscriptionFailedToCreate]:
    errors.SharepointSubscriptionFailedToCreate,
  [ApiError.SharepointSubscriptionFolderNotFound]:
    errors.SharepointSubscriptionFolderNotFound,
  [ApiError.SharepointSubscriptionAlreadyExists]:
    errors.SharepointSubscriptionAlreadyExists,
  [ApiError.ImportArchiveTypeMismatched]: errors.ImportArchiveTypeMismatched,
  [ApiError.WorkspaceNotFound]: errors.WorkspaceNotFound,
  [ApiError.ConfigSnapshotNotFound]: errors.ConfigSnapshotNotFound,
  [ApiError.ConfigSnapshotAlreadyBookmarked]:
    errors.ConfigSnapshotAlreadyBookmarked,
};
