import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractorTestPanel } from "../components/ExtractorTestPanel";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { UserFeatureFlag } from "../constants";
import {
  PrebuiltExtractorDefinition,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import { useExtractTestV2 } from "../hooks/extract_test";
import { useCommonFormGroupContainerState } from "../hooks/form_group";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { ProcessingMode } from "../types/processingMode";
import {
  FormGroupNavBarLayout,
  FormGroupNavTabKey,
} from "./FormGroupNavBarLayout";

type PathParam = {
  formGroupId: string;
};

export const FormGroupTestContainer = React.memo(() => {
  const { formGroupId } = useUnsafeParams<PathParam>();

  const containerState = useCommonFormGroupContainerState(formGroupId);
  const isFeatureEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()
  );
  const options = {
    shouldOutputOrientation: true,
    shouldOutputOcr: isFeatureEnabled(UserFeatureFlag.InspectOCR),
  };

  const hasTransformResponseScript =
    containerState.state === "success"
      ? !!containerState.formGroup.config.transform_response_script?.trim()
      : false;

  const multipleDocumentsPerPage =
    containerState.state === "success"
      ? containerState.formGroup.config.processing_mode ===
        ProcessingMode.MultipleDocumentsPerPage
      : false;

  const {
    extractWithFile,
    extractWithURL,
    extractIfFileKeySet,
    isRecognizing,
    isUploading,
    extractResult,
    extractImageSource,
  } = useExtractTestV2(
    containerState.state === "success"
      ? {
          extractorId: containerState.formGroup.id,
          options,
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
      : {
          extractorId: undefined,
          options,
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
  );

  const prebuiltExtractorDefinition =
    containerState.state === "success" &&
    containerState.formGroup.config.prebuilt_extractor &&
    isPrebuiltExtractors(containerState.formGroup.config.prebuilt_extractor)
      ? PrebuiltExtractorDefinition[
          containerState.formGroup.config.prebuilt_extractor
        ]
      : undefined;

  React.useEffect(() => {
    extractIfFileKeySet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <>
            <FormGroupNavBarLayout
              selectedTab={FormGroupNavTabKey.TestExtractor}
            >
              <ExtractorTestPanel
                resourceName={containerState.formGroup.name}
                samples={
                  prebuiltExtractorDefinition &&
                  prebuiltExtractorDefinition.sampleImages
                }
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                extractImageSource={extractImageSource}
                extractResult={extractResult}
                hasTransformResponseScript={hasTransformResponseScript}
                showExtractorIdTooltip={true}
              />
            </FormGroupNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormGroupTestContainer;
