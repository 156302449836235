import {
  Breadcrumb,
  ChoiceGroup,
  IBreadcrumbItem,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  IRenderFunction,
  Icon,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import classnames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import { CreatablePrebuiltExtractors } from "../../constants/prebuiltExtractor";
import { useLocale } from "../../contexts/locale";
import {
  ExtractorOption,
  PrebuiltExtractor,
  ResourceType,
  mapExtractorTypeToMessageId,
} from "../../types/extractor";
import { DangerButton } from "../DangerButton";
import LoadingModal from "../LoadingModal";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

export enum ExtractorConnection {
  PreTrained = "pre-trained",
  Existing = "existing",
}

type OnCreateWorkspaceInput = {
  workspaceName: string;
} & (
  | {
      extractorConnection: ExtractorConnection.PreTrained;
      extractor: PrebuiltExtractor;
    }
  | {
      extractorConnection: ExtractorConnection.Existing;
      extractor: ExtractorOption;
    }
);

export interface WorkspaceCreateSectionProps {
  extractorOptions?: ExtractorOption[];
  onCreateWorkspace: (input: OnCreateWorkspaceInput) => Promise<void>;
  isCreating: boolean;
  didLoadExtractorOptions: boolean;
}

const getOnRenderField = (
  extractorResourceType: ResourceType
):
  | IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps>
  | undefined => {
  if (extractorResourceType === ResourceType.Form) {
    return (props, render) => {
      return (
        <div className={styles["extractor-row"]}>
          {render?.(props)}
          <div className={styles["extractor-label"]}>
            <Icon
              className={classnames(
                styles["extractor-icon"],
                styles["fixed-layout"]
              )}
              iconName="IconFixedLayout"
            />
            <p className={styles["extractor-type-text"]}>
              <FormattedMessage id="extractor.fixed_layout_extractor" />
            </p>
          </div>
        </div>
      );
    };
  } else if (extractorResourceType === ResourceType.FormGroup) {
    return (props, render) => {
      return (
        <div className={styles["extractor-row"]}>
          {render?.(props)}
          <div className={styles["extractor-label"]}>
            <Icon
              className={classnames(
                styles["extractor-icon"],
                styles["combined"]
              )}
              iconName="IconPuzzle"
            />
            <p className={styles["extractor-type-text"]}>
              <FormattedMessage id="extractor.custom.combine" />
            </p>
          </div>
        </div>
      );
    };
  } else if (extractorResourceType === ResourceType.CustomModel) {
    return (props, render) => {
      return (
        <div className={styles["extractor-row"]}>
          {render?.(props)}
          <div className={styles["extractor-label"]}>
            <Icon
              className={classnames(
                styles["extractor-icon"],
                styles["custom-model"]
              )}
              iconName="IconBoxModel"
            />
            <p className={styles["extractor-type-text"]}>
              <FormattedMessage id="extractor.custom.trained_with_sample" />
            </p>
          </div>
        </div>
      );
    };
  } else {
    return undefined;
  }
};

export function useWorkspaceCreateSection(args: WorkspaceCreateSectionProps) {
  const {
    extractorOptions,
    onCreateWorkspace,
    isCreating,
    didLoadExtractorOptions,
  } = args;
  const navigate = useNavigate();
  const { localized } = useLocale();

  const [workspaceName, setWorkspaceName] = React.useState("");
  const [selectedExtractorConnection, setSelectedExtractorConnection] =
    React.useState<ExtractorConnection>();
  const [selectedPreTrainedExtractor, setSelectedPreTrainedExtractor] =
    React.useState<PrebuiltExtractor>(CreatablePrebuiltExtractors[0]);
  const [selectedExistingExtractor, setSelectedExistingExtractor] =
    React.useState<ExtractorOption>();

  useEffect(() => {
    if (
      didLoadExtractorOptions === true &&
      selectedExtractorConnection == null
    ) {
      if (extractorOptions != null && extractorOptions.length > 0) {
        setSelectedExtractorConnection(ExtractorConnection.Existing);
        setSelectedExistingExtractor(extractorOptions[0]);
      } else {
        setSelectedExtractorConnection(ExtractorConnection.PreTrained);
      }
    }
  }, [extractorOptions, didLoadExtractorOptions, selectedExtractorConnection]);

  const onBreadcrumbItemClick = React.useCallback(
    (event?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (event != null && item?.href != null) {
        event.preventDefault();
        event.stopPropagation();
        navigate(item.href);
      }
    },
    [navigate]
  );

  const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
    return [
      {
        text: localized("workspace.breadcrumb.root"),
        key: "root",
        href: "/workspace",
        onClick: onBreadcrumbItemClick,
      },
      {
        text: localized("workspace.create.title"),
        key: "title",
      },
    ];
  }, [localized, onBreadcrumbItemClick]);

  const extractorConnectionOptions = React.useMemo<
    { key: ExtractorConnection; text: string }[]
  >(() => {
    return [
      {
        key: ExtractorConnection.PreTrained,
        text: localized(
          "workspace.create.field.extractor_connection.option.pre_trained"
        ),
      },
      {
        key: ExtractorConnection.Existing,
        text: localized(
          "workspace.create.field.extractor_connection.option.existing"
        ),
      },
    ];
  }, [localized]);

  const preTrainedExtractorOptions = React.useMemo(() => {
    return CreatablePrebuiltExtractors.map((extractor): IChoiceGroupOption => {
      return {
        key: extractor,
        text: localized(mapExtractorTypeToMessageId(extractor)),
      };
    });
  }, [localized]);

  const existingExtractorOptions = React.useMemo(() => {
    return (extractorOptions ?? []).map((extractor): IChoiceGroupOption => {
      return {
        key: extractor.id,
        text: extractor.name,
        onRenderField: getOnRenderField(extractor.resourceType),
      };
    });
  }, [extractorOptions]);

  const isFormValid = useMemo(() => {
    if (workspaceName.trim().length <= 0) {
      return false;
    }
    if (
      selectedExtractorConnection === ExtractorConnection.Existing &&
      selectedExistingExtractor == null
    ) {
      return false;
    }
    if (
      selectedExtractorConnection === ExtractorConnection.PreTrained &&
      selectedPreTrainedExtractor == null
    ) {
      return false;
    }
    return true;
  }, [
    selectedExistingExtractor,
    selectedExtractorConnection,
    selectedPreTrainedExtractor,
    workspaceName,
  ]);

  const onWorkspaceNameChange = React.useCallback(
    (
      _event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      const input = value ?? "";
      setWorkspaceName(input);
    },
    []
  );

  const onClickUseExistingExtractor = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setSelectedExtractorConnection(ExtractorConnection.Existing);
    },
    []
  );

  const onClickUsePreBuiltExtractor = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setSelectedExtractorConnection(ExtractorConnection.PreTrained);
    },
    []
  );

  const onSelectedPreTrainedExtractorChange = React.useCallback(
    (
      _event?: React.SyntheticEvent<HTMLElement>,
      option?: IChoiceGroupOption
    ): void => {
      if (option?.key != null) {
        setSelectedPreTrainedExtractor(option.key as PrebuiltExtractor);
      }
    },
    []
  );

  const onSelectedExistingExtractorChange = React.useCallback(
    (
      _event?: React.SyntheticEvent<HTMLElement>,
      option?: IChoiceGroupOption
    ): void => {
      const input = extractorOptions?.find(
        extractor => extractor.id === option?.key
      );
      setSelectedExistingExtractor(input);
    },
    [extractorOptions]
  );

  const onCreateWorkspaceClick = React.useCallback(() => {
    if (!isFormValid) {
      return;
    }
    switch (selectedExtractorConnection) {
      case ExtractorConnection.PreTrained: {
        onCreateWorkspace({
          workspaceName: workspaceName.trim(),
          extractorConnection: selectedExtractorConnection,
          extractor: selectedPreTrainedExtractor,
        });
        return;
      }
      case ExtractorConnection.Existing: {
        if (selectedExistingExtractor == null) {
          return;
        }
        onCreateWorkspace({
          workspaceName: workspaceName.trim(),
          extractorConnection: selectedExtractorConnection,
          extractor: selectedExistingExtractor,
        });
        return;
      }
    }
  }, [
    isFormValid,
    onCreateWorkspace,
    selectedExistingExtractor,
    selectedExtractorConnection,
    selectedPreTrainedExtractor,
    workspaceName,
  ]);

  return React.useMemo(
    () => ({
      breadcrumbItems,
      extractorConnectionOptions,
      preTrainedExtractorOptions,
      existingExtractorOptions,
      isCreating,
      isFormValid,
      workspaceName,
      onWorkspaceNameChange,
      selectedExtractorConnection,
      onClickUseExistingExtractor,
      onClickUsePreBuiltExtractor,
      selectedPreTrainedExtractor,
      onSelectedPreTrainedExtractorChange,
      selectedExistingExtractor,
      onSelectedExistingExtractorChange,
      onCreateWorkspaceClick,
    }),
    [
      breadcrumbItems,
      existingExtractorOptions,
      extractorConnectionOptions,
      isFormValid,
      isCreating,
      onClickUseExistingExtractor,
      onClickUsePreBuiltExtractor,
      onCreateWorkspaceClick,
      onSelectedExistingExtractorChange,
      onSelectedPreTrainedExtractorChange,
      onWorkspaceNameChange,
      preTrainedExtractorOptions,
      selectedExistingExtractor,
      selectedExtractorConnection,
      selectedPreTrainedExtractor,
      workspaceName,
    ]
  );
}

export function WorkspaceCreateSectionImpl(
  props: ReturnType<typeof useWorkspaceCreateSection>
) {
  const { localized } = useLocale();
  return (
    <div className={styles["container"]}>
      <Breadcrumb className="pt-3 px-5" items={props.breadcrumbItems} />
      <div className={styles["content"]}>
        <div className={styles["sub-section"]}>
          <div className={styles["field"]}>
            <p className={styles["step-title"]}>
              {localized("workspace.create.field.workspace_name.label")}
            </p>
            <TextField
              value={props.workspaceName}
              onChange={props.onWorkspaceNameChange}
            />
          </div>
          <div className={styles["field"]}>
            <p className={styles["step-title"]}>
              {localized("workspace.create.field.extractor_connection.label")}
              <span className={cn(styles["extractor-connection-description"])}>
                {localized(
                  "workspace.create.field.extractor_connection.description"
                )}
              </span>
            </p>
            {props.selectedExtractorConnection ===
              ExtractorConnection.PreTrained && (
              <div className={styles["sub-section"]}>
                <p className={cn(styles["extractor-list-title"])}>
                  {localized(
                    "workspace.create.field.extractor.label.pre_trained"
                  )}
                </p>
                <ChoiceGroup
                  selectedKey={props.selectedPreTrainedExtractor}
                  options={props.preTrainedExtractorOptions}
                  className={styles["pre-trained-extractor-list"]}
                  onChange={props.onSelectedPreTrainedExtractorChange}
                />
                <ActionButton
                  className={styles["switch-connection-button"]}
                  textId="workspace.create.field.extractor_connection.option.existing"
                  onClick={props.onClickUseExistingExtractor}
                />
              </div>
            )}
            {props.selectedExtractorConnection ===
              ExtractorConnection.Existing && (
              <div className={styles["sub-section"]}>
                <p className={cn(styles["extractor-list-title"])}>
                  {localized("workspace.create.field.extractor.label.existing")}
                </p>
                <ChoiceGroup
                  selectedKey={props.selectedExistingExtractor?.id}
                  options={props.existingExtractorOptions}
                  className={styles["pre-trained-extractor-list"]}
                  onChange={props.onSelectedExistingExtractorChange}
                />
                <ActionButton
                  className={styles["switch-connection-button"]}
                  textId="workspace.create.field.extractor_connection.option.pre_trained"
                  onClick={props.onClickUsePreBuiltExtractor}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-5 pb-[30px]">
        <div className="h-[1px] bg-[#edebe9] mb-[11px]" />
        <div className="flex justify-end">
          <DangerButton
            textId="common.create"
            onClick={props.onCreateWorkspaceClick}
            disabled={props.isCreating || !props.isFormValid}
          />
        </div>
      </div>
      <LoadingModal isOpen={props.isCreating} />
    </div>
  );
}

export function WorkspaceCreateSection(args: WorkspaceCreateSectionProps) {
  const props = useWorkspaceCreateSection(args);
  return <WorkspaceCreateSectionImpl {...props} />;
}
