import { loadTheme } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { enableMapSet, setAutoFreeze } from "immer";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import "./_polyfill";
import { apiClient } from "./apiClient";
import { ToastProvider } from "./components/Toast";
import WorkspaceTaskRunningPrompt from "./components/WorkspaceTaskRunningPrompt";
import { AppConfig } from "./config";
import { MainRouter } from "./containers/MainRouter";
import {
  CustomModelImageServiceInUsePrompt,
  CustomModelImageServiceProvider,
} from "./contexts/customModelImageService";
// Apply global style
import { LocaleProvider } from "./contexts/locale";
import createStore from "./redux/createStore";
import { createCustomModelImageService } from "./services/customModelImage";
import "./styles/import-styles";
import Theme from "./theme";
import { initalizeGTM } from "./utils/gtm";
import { registerCustomIcons } from "./utils/icon";

document.title = AppConfig.documentTitle || "FormExtractor";

initalizeGTM();
enableMapSet();
setAutoFreeze(false);
loadTheme(Theme);

const fontBaseUrl = (window as any).FabricConfig?.fontBaseUrl;
if (fontBaseUrl) {
  initializeIcons(`${fontBaseUrl}/fabric-icon-fonts/`);
} else {
  initializeIcons();
}
registerCustomIcons();

if (!AppConfig.shouldTawkDisabled) {
  const script = document.createElement("script");
  script.src = "https://embed.tawk.to/61494c7bd326717cb6827f37/1fg355ho5";
  script.async = true;
  script.crossOrigin = "*";
  document.body.appendChild(script);
}

const store = createStore();
const customModelImageService = createCustomModelImageService(apiClient);

const element = (
  <LocaleProvider locale="en">
    <Provider store={store}>
      <ToastProvider
        placement="top-center"
        autoDismiss={true}
        autoDismissTimeout={5000}
      >
        <CustomModelImageServiceProvider service={customModelImageService}>
          <MainRouter />
          <CustomModelImageServiceInUsePrompt />
          <WorkspaceTaskRunningPrompt />
        </CustomModelImageServiceProvider>
      </ToastProvider>
    </Provider>
  </LocaleProvider>
);

render(element, document.getElementById("root"));
