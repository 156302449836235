import React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import { ExtractAPIV2Document } from "../../models";
import { ExtractAPIV2ResponseViewer } from "../ExtractAPIV2ResponseViewer";
import styles from "./styles.module.scss";

export enum ViewMode {
  KeyValue,
  Json,
  ImageQuality,
}

interface ResultAssetViewerProps {
  resultAsset: ExtractAPIV2Document;
  assetUrl?: string;
  viewMode: ViewMode;
}
export function ResultAssetViewer(props: ResultAssetViewerProps) {
  const { resultAsset, assetUrl, viewMode } = props;

  if (viewMode === ViewMode.Json) {
    return (
      <div className={styles.resultAssetJsonContainer}>
        <pre className={styles.resultAssetJson}>
          {JSON.stringify(resultAsset, null, JSON_INDENTATION)}
        </pre>
      </div>
    );
  }

  if (
    viewMode === ViewMode.ImageQuality &&
    resultAsset.metadata?.image_quality != null
  ) {
    return (
      <div className={styles.resultAssetJsonContainer}>
        <pre className={styles.resultAssetJson}>
          {JSON.stringify(
            resultAsset.metadata?.image_quality,
            null,
            JSON_INDENTATION
          )}
        </pre>
      </div>
    );
  }

  return (
    <div className={styles.resultAssetViewerContainer}>
      <ExtractAPIV2ResponseViewer
        response={resultAsset as ExtractAPIV2Document}
        sourceImage={assetUrl}
      />
    </div>
  );
}
