import { Values as _LocalizerValue } from "@oursky/react-messageformat";
import produce, { castDraft } from "immer";

import { accessNumberListBoundingBox } from "./types/boundingBox";
import { DetectionRegionField } from "./types/detectionRegion";
import { RenderDocument } from "./types/document";
import {
  ExtractedContentSchema,
  ExtractedContentSchemaStorage,
  ExtractedContentSchemaType,
} from "./types/extractedContentSchema";
import {
  ExtractorFieldValueType,
  castExtractorFieldValueType,
} from "./types/extractor";
import { DocumentDetectionType } from "./types/formGroup";
import {
  ScriptEditorModalPayloadForDetectionRegionField,
  ScriptEditorModalPayloadForForm,
  ScriptEditorModalPayloadForFormGroup,
} from "./types/scriptEditorModalPayload";

export type Dimension = {
  width: number;
  height: number;
};

export type OCRTestField = {
  region_id: string;
  name: string;
  type: string;
  value: any;
  image: string;
  error?: string;
  confidence?: number | null;
};

export type OCRKeyValue = {
  name: string;
  value: any;
  confidence?: number | null;
  image?: string;
};

export type OCRToken = {
  id: string;
  value: string;
  confidence?: number | null;
};

export type OCRTokenGroup = {
  name: string;
  texts?: OCRToken[];
  images?: OCRToken[];
};

export type CustomModelFieldResult = {
  value: any;
  confidence?: number | null;
};

export type CustomModelSectionResult = {
  [key in string]: CustomModelFieldResult[];
};

export type FSLCustomModelSessionResult = {
  [key in string]: string | string[] | null;
};

export type CustomModelResult = {
  id: string;
  name: string;
  model_version: string;
  result:
    | { [key in string]: CustomModelSectionResult }
    | FSLCustomModelSessionResult;
};

export type OCRWorkflowOutput = {
  name: string;
  value: any;
};

export type OCRTestReportSingleDocument = {
  form_id: string;
  warped_image: string;
  fields: OCRTestField[];
  key_values?: OCRKeyValue[];
  token_groups?: OCRTokenGroup[];
  auto_extraction_items?: OCRKeyValue[];
  custom_models?: CustomModelResult[];
  formatter_output?: OCRWorkflowOutput[];
  ocr?: string;
};

export type OCRTestReportError = {
  error: {
    code: number;
    message: string;
  };
};

export type OCRTestReportMultipleDocumentItem = OCRTestReportSingleDocument & {
  bbox?: number[];
  type?: DocumentDetectionType;
};

export type OCRTestReportMultipleDocument = {
  documents: (OCRTestReportMultipleDocumentItem | OCRTestReportError)[];
};

export type OCRTestReport =
  | OCRTestReportSingleDocument
  | OCRTestReportMultipleDocument;

interface ExtractV2APIBaseDataValue {
  confidence?: number; // 0 to 1
  bounding_box?: number[]; // left, top, bottom, right in fraction

  extracted_by: string; // See 3.2

  // Following fields are undocumented and for internal use only
  image?: string; // base64 encoded image
}

interface ExtractAPIV2ScalarValue extends ExtractV2APIBaseDataValue {
  value: string | number | boolean;
  value_type: "scalar";
}

// usage: FSL list field
interface ExtractAPIV2ListOfScalarValue extends ExtractV2APIBaseDataValue {
  value: (string | number | boolean)[];
  value_type: "list_of_scalar";
}

// usage: name with title
interface ExtractAPIV2DictValue extends ExtractV2APIBaseDataValue {
  value: { [key: string]: any };
  value_type: "dict";
}

// usage: product-line items
interface ExtractV2ListOfDictValue extends ExtractV2APIBaseDataValue {
  value: { [key: string]: any }[];
  value_type: "list_of_dict";
}

export type ExtractAPIV2DocumentDetailedDataValue =
  | ExtractAPIV2ScalarValue
  | ExtractAPIV2ListOfScalarValue
  | ExtractAPIV2DictValue
  | ExtractV2ListOfDictValue;

export interface ExtractAPIV2Document {
  extractor_id: string; // extractor_id that actully extractor this document item

  metadata?: {
    page_no: number | number[]; // start from 1
    slice_no: number; // start from 1, will be reset for different page
    extractor_type: string;
    orientation: number;
    image_quality?: { [key: string]: any }[];
  };

  // Following two fields exists if detect_multi_document=true in request
  type?: string;
  type_confidence?: number; // 0 to 1
  bounding_box?: number[]; // [left, top, bottom, right], in fraction

  data: {
    [key: string]:
      | string
      | number
      | boolean
      | (string | number | boolean)[]
      | { [key: string]: any }
      | { [key: string]: any }[]
      | null;
  };

  detailed_data: {
    // key is the user-defined field name
    // value is always an array
    // See 3.1, 3.11
    [key: string]: ExtractAPIV2DocumentDetailedDataValue[];
  };

  formatter_output?: {
    // key is the user-defined field name in formatter
    // value is always an array
    // See 3.1
    [key: string]: (
      | ExtractAPIV2ScalarValue
      | ExtractAPIV2ListOfScalarValue
      | ExtractAPIV2DictValue
      | ExtractV2ListOfDictValue
    )[];
  };

  // Following fields are undocumented and for internal use only
  image?: string; // base64 encoded image
  llm_prompt?: string[];

  // The OCR result of the document. If it available only if X-WORKER-OUTPUT-OCR
  // was set
  ocr?: string | string[];

  error?: {
    code: number;
    message: string;
    info?: { [key: string]: any };
  };
}

export type HiddenBoundingBoxIndices = Map<string, number[]>;

export class HiddenBoundingBoxIndicesAccessor {
  data: HiddenBoundingBoxIndices;

  constructor(data: Map<string, number[]>) {
    this.data = data;
  }

  get() {
    return this.data;
  }

  addIndex(label: string, index: number) {
    const indices = this.data.get(label);
    if (indices !== undefined && !indices.includes(index)) {
      this.data.set(label, [...indices, index]);
    } else {
      this.data.set(label, [index]);
    }
    return this;
  }

  removeIndex(label: string, index: number) {
    const indices = this.data.get(label);
    if (indices !== undefined) {
      this.data.set(
        label,
        indices.filter(i => i !== index)
      );
    }
    return this;
  }

  toggleIndex(label: string, index: number) {
    if (this.includes(label, index)) {
      this.removeIndex(label, index);
    } else {
      this.addIndex(label, index);
    }
  }

  includes(label: string, index: number) {
    return this.data.get(label)?.includes(index) ?? false;
  }
}

export function accessHiddenBoundingBoxIndices(data?: Map<string, number[]>) {
  return new HiddenBoundingBoxIndicesAccessor(data ?? new Map());
}

export class ExtractAPIV2DocumentAccessor {
  data: ExtractAPIV2Document;

  constructor(data: ExtractAPIV2Document) {
    this.data = data;
  }

  static createFromExtractedContentSchema(
    extractedContentSchema: ExtractedContentSchema,
    result?: Record<string, any>
  ): ExtractAPIV2Document {
    const detailed_data: Record<
      string,
      ExtractAPIV2DocumentDetailedDataValue[]
    > = {};

    extractedContentSchema.payload.map(field => {
      const value = result?.[field.name];
      if (value === undefined) {
        return;
      }

      let valueType;

      if (field.type === ExtractedContentSchemaType.FieldGroup) {
        valueType = field.isList
          ? ExtractorFieldValueType.ListOfDict
          : ExtractorFieldValueType.Dict;
      } else {
        valueType = field.isList
          ? ExtractorFieldValueType.ListOfScalar
          : ExtractorFieldValueType.Scalar;
      }

      detailed_data[field.name] = [
        {
          extracted_by: "",
          value: castExtractorFieldValueType(valueType, value),
          value_type: valueType,
        },
      ];
    });

    return {
      extractor_id: "",
      metadata: {
        page_no: 1,
        slice_no: 1,
        extractor_type: "",
        orientation: 0,
      },
      data: result as any,
      detailed_data,
    };
  }

  removeImageField() {
    this.data = produce(this.data, draft => {
      delete draft.image;

      if (draft.detailed_data == null) {
        return;
      }

      Object.entries(draft.detailed_data).map(([_key, items]) => {
        (items as any[]).forEach(item => {
          delete item.image;
        });
      });
    });

    return this;
  }

  removeHiddenFields() {
    this.data = produce(this.data, draft => {
      delete draft.llm_prompt;
    });
    return this;
  }

  setImageField(image: string) {
    this.data = produce(this.data, draft => {
      draft.image = image;
    });
    return this;
  }

  extractRenderDocumentBoundingBoxes(
    selectedResultIndex?: number,
    hiddenBoundingBoxIndices?: HiddenBoundingBoxIndices
  ) {
    const result: number[][] = [];

    if (
      this.data.detailed_data !== undefined &&
      hiddenBoundingBoxIndices !== undefined &&
      (!Array.isArray(this.data.metadata?.page_no) || selectedResultIndex === 0)
    ) {
      Object.entries(this.data.detailed_data).forEach(([label, object]) => {
        if (Array.isArray(object)) {
          object.forEach((item, index) => {
            if (
              !accessHiddenBoundingBoxIndices(
                hiddenBoundingBoxIndices
              ).includes(label, index) &&
              item.bounding_box
            ) {
              result.push(
                accessNumberListBoundingBox(
                  this.data.bounding_box ?? [0, 0, 1, 1]
                ).subRegion(item.bounding_box).data
              );
            }
          });
        }
      });
    }

    return result;
  }

  extractRenderDocument(
    selectedResultIndex?: number,
    hiddenBoundingBoxIndices?: HiddenBoundingBoxIndices
  ) {
    return {
      url: this.data.image,
      rotation:
        this.data.metadata?.orientation !== undefined
          ? -this.data.metadata.orientation
          : 0,
      texts: [],
      boundingBoxes: this.extractRenderDocumentBoundingBoxes(
        selectedResultIndex,
        hiddenBoundingBoxIndices
      ),
    } as RenderDocument;
  }

  setExtractorId(extractorId: string) {
    this.data = produce(this.data, draft => {
      draft.extractor_id = extractorId;
    });
    return this;
  }

  setExtractorType(extractorType: string) {
    this.data = produce(this.data, draft => {
      if (draft.metadata == null) {
        draft.metadata = {
          page_no: 1,
          slice_no: 1,
          extractor_type: extractorType,
          orientation: 0,
        };
      } else {
        draft.metadata.extractor_type = extractorType;
      }
    });
    return this;
  }
}

export function accessExtractAPIV2Document(data: ExtractAPIV2Document) {
  return new ExtractAPIV2DocumentAccessor(data);
}

// Mapping between ExtractAPIV2Document and input images
export interface ExtractAPIV2ImageDocumentIndexTuple {
  imageIndex: number;
  documentIndex: number;
}

export interface ExtractAPIV2SuccessResponse {
  status: "ok";
  metadata: {
    job_id?: string; // exists for async cal
    extractor_id: string; // extractor_id in /extract request
    request_id: string;
    usage: number; // See 3.9
  };
  documents: ExtractAPIV2Document[];
}

export type ExtractAPIV2Response = ExtractAPIV2SuccessResponse;

export class ExtractAPIV2ResponseAccessor {
  data: ExtractAPIV2Response;

  constructor(data: ExtractAPIV2Response) {
    this.data = data;
  }

  static createFromExtractedContentSchema(
    extractedContentSchema: ExtractedContentSchema,
    result?: Record<string, any>
  ): ExtractAPIV2Response {
    return {
      status: "ok",
      metadata: {
        extractor_id: "",
        request_id: "",
        usage: 0,
      },
      documents: [
        ExtractAPIV2DocumentAccessor.createFromExtractedContentSchema(
          extractedContentSchema,
          result
        ),
      ],
    };
  }

  setDocumentImage(images: string[]) {
    const documents = this.data.documents.map((doc, i) => {
      try {
        if (images[i] !== undefined) {
          return accessExtractAPIV2Document(doc).setImageField(images[i]).data;
        }
      } catch {}
      return doc;
    });

    this.data = produce(this.data, draft => {
      draft.documents = documents;
    });
    return this;
  }

  extractRenderDocuments() {
    return this.data.documents.map(doc => {
      return accessExtractAPIV2Document(doc).extractRenderDocument();
    });
  }

  // Create a mapping from image to document (may not be 1:1 mapping)
  findImageDocumentIndexTuples(
    imageCount: number
  ): ExtractAPIV2ImageDocumentIndexTuple[] {
    const tuples = Array.from(Array(imageCount).keys())
      .map(imageIndex => {
        const res = this.data.documents
          .map((document, documentIndex) => {
            const pages =
              document.metadata?.page_no == null
                ? []
                : Array.isArray(document.metadata.page_no)
                ? document.metadata.page_no
                : [document.metadata.page_no];

            return pages.includes(imageIndex + 1)
              ? {
                  imageIndex,
                  documentIndex,
                }
              : undefined;
          })
          .filter(item => item !== undefined);
        return res.length === 0 ? [{ imageIndex, documentIndex: -1 }] : res;
      })
      .flat() as ExtractAPIV2ImageDocumentIndexTuple[];

    // Process unmapped image to doucment due to missing metadata
    // in error document
    // Unfortunatly, it have no page_no in an error document.
    // The mapping may not be correct.
    const unmappedDocuments = this.data.documents
      .map((document, documentIndex) => {
        return [document, documentIndex];
      })
      .filter(item => {
        return (item[0] as ExtractAPIV2Document).metadata?.page_no == null;
      })
      .map(item => item[1]) as number[];
    if (unmappedDocuments.length === 0) {
      return tuples;
    }

    let unmappedDocumentIndex = 0;
    return tuples.map(tuple => {
      if (tuple.documentIndex < 0) {
        const index =
          unmappedDocumentIndex >= unmappedDocuments.length
            ? unmappedDocuments.length - 1
            : unmappedDocumentIndex;
        unmappedDocumentIndex++;
        return {
          imageIndex: tuple.imageIndex,
          documentIndex: unmappedDocuments[index],
        };
      }
      return tuple;
    });
  }

  setExtractorId(extractorId: string): ExtractAPIV2ResponseAccessor {
    this.data = produce(this.data, draft => {
      draft.metadata.extractor_id = extractorId;
      draft.documents = castDraft(draft).documents.map(
        doc => accessExtractAPIV2Document(doc).setExtractorId(extractorId).data
      );
    });
    return this;
  }

  setExtractorType(extractorType: string): ExtractAPIV2ResponseAccessor {
    this.data = produce(this.data, draft => {
      draft.documents = castDraft(draft).documents.map(
        doc =>
          accessExtractAPIV2Document(doc).setExtractorType(extractorType).data
      );
    });
    return this;
  }

  setRequestId(requestId: string): ExtractAPIV2ResponseAccessor {
    this.data = produce(this.data, draft => {
      draft.metadata.request_id = requestId;
    });
    return this;
  }
}

export function accessExtractAPIV2Response(data: ExtractAPIV2Response) {
  return new ExtractAPIV2ResponseAccessor(data);
}

export type ExtractV2TableValue = {
  cells: ({
    end_col: number;
    end_row: number;
    start_col: number;
    start_row: number;
    text: string;
  } | null)[][];
  metadata: {
    num_of_cols: number;
    num_of_rows: number;
  };
};

/* End of V2 Response */

export type DetectMultiDocumentDocument = {
  bbox: number[];
  bbox_score: number;
  type: string;
  type_score: number;
};

export type DetectMultiDocumentReport = {
  documents: DetectMultiDocumentDocument[];
  warped_image: string;
};

export type DetectPIIEntity = {
  value: string;
  type: string;
  confidence: number;
  bboxes: number[][];
};

export type DetectPIIReport = {
  pii_entities: DetectPIIEntity[];
  debug_image?: string;
  redacted_image?: string;
};

export type DetectExtractedContentSchemaReport = {
  extracted_content_schema: ExtractedContentSchemaStorage;
  extraction_result: Record<string, any>;
};

export interface AutoExtractionName {
  title: string;
  name: string;
}

export interface LLMCompletionResultWithPrompt {
  prompt: string;
  result: any;
}

export function isAutoExtractionName(val: any): val is AutoExtractionName {
  return val instanceof Object && "title" in val && "name" in val;
}

export function isLLMCompletionResultWithPrompt(
  val: any
): val is AutoExtractionName {
  return val instanceof Object && "prompt" in val && "result" in val;
}

export type DetectedCustomField = {
  name: string;
  value: string;
};

export type APIParameterAction = "asyncMode";

export type APIParameter = {
  name: string;
  defaultValue?: string;
  isOptional?: boolean;
  actions?: APIParameterAction[];
};

export type APIResponseField = {
  name: string;
  isOptional?: boolean;
  fieldType: string;
};

export type APIType = {
  name: string;
  fields: APIResponseField[];
};

export type APIDescription = {
  name: string;
  method: string;
  endpoint: string;
  cURLExample: string;

  headerParams: APIParameter[];
  formParams: APIParameter[];
  responseFields: APIResponseField[];
  types?: APIType[];
};

export interface SelectOption {
  label: string;
  value: string;
}

export interface ScriptFunctionContent {
  name: string;
  parameters: ScriptFunctionParameter[];
  returnValue?: ScriptFunctionParameter;
}

export interface ScriptFunctionParameter {
  name: string;
  type: string;
  isOptional?: boolean;
  fields?: ScriptFunctionParameter[];
}

export type ExternalServices = "azure" | "google";

export type ScriptEditorModalPayload =
  | ScriptEditorModalPayloadForDetectionRegionField
  | ScriptEditorModalPayloadForForm
  | ScriptEditorModalPayloadForFormGroup;

export interface BaseFieldSettingModalPayload {
  field: DetectionRegionField;
  index: number;
  selectedDetectionRegionId: string;
}

export interface MinimalFieldModalPayload extends BaseFieldSettingModalPayload {
  titleId: string;
}

export type PathParam = {
  formId: string;
  formGroupId: string;
  customModelId: string;
  workspaceId: string;
  documentId: string;
  extractionResultId: string;
  page: string;
};

export type AdminPathParam = {
  teamId: string;
  region: string;
  customModelId: string;
};

export type FormEditorTab = "region" | "document" | "settings";

export interface UploadAssetResponse {
  url?: string;
  name: string;
}

export interface UpdateImagePayload {
  imageUrl: string | null;
  imageId: string | null;
}

export enum ModalState {
  OpenedToEdit,
  OpenedToCreate,
  Closed,
}

export type LocalizerValue = _LocalizerValue;

export type Localizer = (key: string, values?: LocalizerValue) => string;

export interface ToastOptions {
  id?: string;
  autoDismiss?: boolean;
  onDismiss?: (id: string) => void;
}
