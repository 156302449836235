import classNames from "classnames";
import React, { memo } from "react";

import { useLocale } from "../../contexts/locale";
import { TableDiffDescription } from "../../utils/tableDiff";

interface TableDiffInfo {
  name?: string;
  description: TableDiffDescription;
}

export interface TableDiffDescriptionGroup {
  readonly name: string;
  readonly tables: TableDiffInfo[];
}

interface Props {
  className?: string;
  group: TableDiffDescriptionGroup;
}

const TableDiffLogGroup = memo((props: Props) => {
  const { className, group } = props;
  const { localized } = useLocale();

  const descriptionStyle = "text-sm text-[#605E5C] font-semibold";
  const isTableScopedLineVisible = group.tables.length > 1;

  return (
    <div className={className}>
      <p className="text-sm text-[#323130] font-semibold mb-1">{group.name}</p>
      {group.tables.map((c, i) => (
        <div key={i} className="py-1 flex">
          <span
            className={classNames("w-[2px]  rouned mx-2", {
              "bg-[#C8C6C4]": isTableScopedLineVisible,
            })}
          />
          <div className="my-1">
            {c.name && (
              <p className="text-xs text-[#8A8886] font-semibold mb-1">
                {c.name}
              </p>
            )}
            {c.description.added > 0 && (
              <p className={descriptionStyle}>
                {localized(
                  "snapshot_version_change_logs.table_diff_log.description.added_with_count",
                  {
                    count: c.description.added,
                  }
                )}
              </p>
            )}
            {c.description.removed > 0 && (
              <p className={descriptionStyle}>
                {localized(
                  "snapshot_version_change_logs.table_diff_log.description.removed_with_count",
                  {
                    count: c.description.removed,
                  }
                )}
              </p>
            )}
            {c.description.updated > 0 && (
              <p className={descriptionStyle}>
                {localized(
                  "snapshot_version_change_logs.table_diff_log.description.updated_with_count",
                  {
                    count: c.description.updated,
                  }
                )}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});

export default TableDiffLogGroup;
