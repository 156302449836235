import cntl from "cntl";
import React, { memo, useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import {
  ConfigSnapshot,
  ConfigSnapshotSearchableItem,
} from "../../types/configSnapshot";
import { formatDate } from "../../utils/datetime";
import SearchableDropdown from "../SearchableDropdown";
import { dateTimePattern } from "../SnapshotVersionList/config";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import TableDiffLogGroup, {
  TableDiffDescriptionGroup,
} from "./TableDiffLogGroup";

interface useSnapshotVersionChangeLogsProps {
  className?: string;
  fromVersionOptions: ConfigSnapshotSearchableItem[];
  toVersionOptions: ConfigSnapshotSearchableItem[];
  isLoadingVersions?: boolean;
  selectedFromVersionKey?: string;
  selectedToVersionKey?: string;
  selectedFromVersion?: ConfigSnapshot;
  selectedToVersion?: ConfigSnapshot;
  tableSummaryGroups: TableDiffDescriptionGroup[];
  onSelectFromVersion: (version: ConfigSnapshotSearchableItem) => void;
  onSelectToVersion: (version: ConfigSnapshotSearchableItem) => void;
  onDownloadExcel: (from: ConfigSnapshot, to: ConfigSnapshot) => void;
}

export function useSnapshotVersionChangeLogsState(
  props: useSnapshotVersionChangeLogsProps
) {
  const {
    className,
    fromVersionOptions,
    toVersionOptions,
    selectedToVersion,
    selectedFromVersion,
    selectedFromVersionKey,
    selectedToVersionKey,
    onDownloadExcel,
    tableSummaryGroups,
    onSelectFromVersion,
    onSelectToVersion,
    isLoadingVersions,
  } = props;

  const fromOptions = useMemo(() => {
    return fromVersionOptions.map(v => ({
      id: v.id,
      key: v.id,
      text: v.name ?? formatDate(v.createdAt, dateTimePattern),
    }));
  }, [fromVersionOptions]);
  const toOptions = useMemo(() => {
    return toVersionOptions.map(v => ({
      id: v.id,
      key: v.id,
      text: v.name ?? formatDate(v.createdAt, dateTimePattern),
    }));
  }, [toVersionOptions]);

  const onSelectFromOption = useCallback(
    (key: number | string) => {
      const item = fromVersionOptions.find(o => o.id === key);
      if (item) {
        onSelectFromVersion(item);
      }
    },
    [fromVersionOptions, onSelectFromVersion]
  );

  const onSelectToOption = useCallback(
    (key: number | string) => {
      const item = toVersionOptions.find(o => o.id === key);
      if (item) {
        onSelectToVersion(item);
      }
    },
    [toVersionOptions, onSelectToVersion]
  );
  const onDownloadExcelPressed = useCallback(() => {
    if (selectedFromVersion && selectedToVersion) {
      onDownloadExcel(selectedFromVersion, selectedToVersion);
    }
  }, [selectedFromVersion, selectedToVersion, onDownloadExcel]);
  return React.useMemo(() => {
    return {
      fromVersionOptions: fromOptions,
      toVersionOptions: toOptions,
      onDownloadExcel: onDownloadExcelPressed,
      onSelectFromOption,
      onSelectToOption,
      selectedFromVersionKey,
      selectedToVersionKey,
      tableSummaryGroups,
      selectFromVersion: selectedFromVersion,
      selectToVersion: selectedToVersion,
      className,
      isLoadingVersions,
    };
  }, [
    fromOptions,
    toOptions,
    onDownloadExcelPressed,
    onSelectFromOption,
    onSelectToOption,
    tableSummaryGroups,
    selectedFromVersion,
    selectedToVersion,
    className,
    selectedFromVersionKey,
    selectedToVersionKey,
    isLoadingVersions,
  ]);
}

const SnapshotVersionChangeLogsImpl = memo(
  (props: ReturnType<typeof useSnapshotVersionChangeLogsState>) => {
    const {
      className,
      onDownloadExcel,
      tableSummaryGroups,
      fromVersionOptions,
      toVersionOptions,
      onSelectToOption,
      onSelectFromOption,
      selectedFromVersionKey,
      selectedToVersionKey,
      selectFromVersion,
      selectToVersion,
      isLoadingVersions,
    } = props;
    const { localized } = useLocale();

    return (
      <div className={cntl`flex flex-1 flex-col w-[340px] ${className}`}>
        <p className="px-6 pt-5 pb-3.5 font-semibold text-xl">
          {localized("snapshot_version_change_logs.title")}
        </p>
        <div className="flex flex-1 flex-col overflow-y-auto">
          <div className="mx-[22px] mb-4">
            <p className="py-1 font-semibold text-sm text-[#201F1E]">
              {localized("snapshot_version_change_logs.label.compare")}
            </p>
            <SearchableDropdown
              options={toVersionOptions}
              onSelected={onSelectToOption}
              selectedKey={selectedToVersionKey}
              isLoading={isLoadingVersions}
            />
          </div>
          <div className="mx-[22px] pb-6 border-[#EDEBE9] border border-solid border-t-0 border-x-0">
            <p className="py-1 font-semibold text-sm text-[#201F1E]">
              {localized("snapshot_version_change_logs.label.to")}
            </p>
            <SearchableDropdown
              options={fromVersionOptions}
              onSelected={onSelectFromOption}
              selectedKey={selectedFromVersionKey}
              isLoading={isLoadingVersions}
            />
          </div>
          <div className="mx-[22px] my-3">
            {tableSummaryGroups.map(group => {
              return (
                <TableDiffLogGroup
                  key={group.name}
                  className="my-3"
                  group={group}
                />
              );
            })}
          </div>
        </div>
        <div className="flex px-6 py-4 justify-end">
          <PrimaryButton
            textId="snapshot_version_change_logs.action.download_excel"
            disabled={
              selectFromVersion === undefined || selectToVersion === undefined
            }
            onClick={onDownloadExcel}
          />
        </div>
      </div>
    );
  }
);

const SnapshotVersionChangeLogs = memo(
  (props: useSnapshotVersionChangeLogsProps) => {
    const state = useSnapshotVersionChangeLogsState(props);
    return <SnapshotVersionChangeLogsImpl {...state} />;
  }
);

export default SnapshotVersionChangeLogs;
