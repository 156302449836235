import { Link, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";
import * as uuid from "uuid";

import { useTeamPermission } from "../../hooks/permission";
import { UploadAssetResponse } from "../../models";
import { FormGroupTokenGroup } from "../../types/formGroup";
import {
  TokenGroupImage,
  TokenGroupText,
  TokenMatchingMode,
} from "../../types/tokenGroup";
import { uploadAsset } from "../../utils/file";
import { isValidRegExp } from "../../utils/regexp";
import ImageTokenModal from "../ImageTokenModal";
import { ImageTokenCard, TextTokenCard } from "../TokenGroupContent";
import TokenGroupTextModal from "../TokenGroupTextModal";
import styles from "./styles.module.scss";

interface Props {
  tokenGroup: FormGroupTokenGroup;
  onAddTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textToken: TokenGroupText
  ) => void;
  onUpdateTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textToken: TokenGroupText
  ) => void;
  onDeleteTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textTokenId: string
  ) => void;
  onAddImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageToken: TokenGroupImage
  ) => void;
  onUpdateImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageToken: TokenGroupImage
  ) => void;
  onDeleteImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageTokenId: string
  ) => void;
}

export const FormGroupTokenGroupContent = React.memo((props: Props) => {
  const {
    tokenGroup,
    onAddImageToken,
    onAddTextToken,
    onUpdateImageToken,
    onUpdateTextToken,
    onDeleteTextToken,
    onDeleteImageToken,
  } = props;

  const [isUploadingAsset, setIsUploadingAsset] =
    React.useState<boolean>(false);
  const [isTextTokenModalOpen, setIsTextTokenModalOpen] =
    React.useState<boolean>(false);
  const [isImageTokenModalOpen, setIsImageTokenModalOpen] =
    React.useState<boolean>(false);
  const [edittingTextToken, setEdittingTextToken] = React.useState<
    TokenGroupText | undefined
  >(undefined);
  const [edittingImageToken, setEdittingImageToken] = React.useState<
    TokenGroupImage | undefined
  >(undefined);
  const { hasPermissionToEditResource } = useTeamPermission();

  const handleDeleteTextTokenCallbacks = React.useMemo(() => {
    return tokenGroup.texts.map(text => {
      return () => {
        onDeleteTextToken(tokenGroup, text.id);
      };
    });
  }, [onDeleteTextToken, tokenGroup]);

  const handleImageTokenCallbacks = React.useMemo(() => {
    return tokenGroup.images.map(image => {
      return () => {
        onDeleteImageToken(tokenGroup, image.id);
      };
    });
  }, [onDeleteImageToken, tokenGroup]);

  const checkIfImageTokenNameUnique = React.useCallback(
    (name: string) => {
      const isSameAsOriginal = !!(
        edittingImageToken && edittingImageToken.name === name
      );
      const isNameUnique =
        tokenGroup.images.find(image => image.name === name) === undefined;

      return isNameUnique || isSameAsOriginal;
    },
    [tokenGroup, edittingImageToken]
  );

  const validateTextTokenValue = React.useCallback(
    (value: string, tokenMatchingMode?: TokenMatchingMode) => {
      const isSameAsOriginal =
        edittingTextToken && edittingTextToken.value === value;
      const isValueUnique =
        tokenGroup.texts.find(textToken => textToken.value === value) ===
        undefined;

      if (!value.trim()) {
        return "error.text_token_modal.empty_value";
      } else if (!isSameAsOriginal && !isValueUnique) {
        return "error.text_token_modal.duplicated_value";
      } else if (
        tokenMatchingMode === TokenMatchingMode.Regex &&
        !isValidRegExp(value)
      ) {
        return "error.text_token_modal.invalid_regexp";
      }
      return undefined;
    },
    [tokenGroup, edittingTextToken]
  );

  const openImageTokenModal = React.useCallback(
    (imageToken: TokenGroupImage | undefined) => {
      setEdittingImageToken(imageToken);
      setIsImageTokenModalOpen(true);
    },
    []
  );

  const closeImageTokenModal = React.useCallback(() => {
    setEdittingImageToken(undefined);
    setIsImageTokenModalOpen(false);
  }, []);

  const openTextTokenModal = React.useCallback(
    (textToken: TokenGroupText | undefined) => {
      setEdittingTextToken(textToken);
      setIsTextTokenModalOpen(true);
    },
    []
  );

  const closeTextTokenModal = React.useCallback(() => {
    setEdittingTextToken(undefined);
    setIsTextTokenModalOpen(false);
  }, []);

  const onCreateTextToken = React.useCallback(() => {
    openTextTokenModal(undefined);
  }, [openTextTokenModal]);

  const onCreateImageToken = React.useCallback(() => {
    openImageTokenModal(undefined);
  }, [openImageTokenModal]);

  const onEditTextToken = React.useCallback(
    (textToken: TokenGroupText) => {
      return () => {
        openTextTokenModal(textToken);
      };
    },
    [openTextTokenModal]
  );

  const onEditImageToken = React.useCallback(
    (imageToken: TokenGroupImage) => {
      return () => {
        openImageTokenModal(imageToken);
      };
    },
    [openImageTokenModal]
  );

  const onSubmitTextToken = React.useCallback(
    (
      tokenText: string,
      tokenMatchingMode: TokenMatchingMode,
      isIgnoreWhiteSpace: boolean
    ) => {
      const isExactMatchOnly =
        tokenMatchingMode === TokenMatchingMode.ExactMatch;
      const isRegex = tokenMatchingMode === TokenMatchingMode.Regex;

      if (edittingTextToken) {
        const token: TokenGroupText = {
          ...edittingTextToken,
          value: tokenText,
          isExactMatchOnly,
          isRegex,
          isIgnoreWhiteSpace,
        };
        onUpdateTextToken(tokenGroup, token);
      } else {
        const token: TokenGroupText = {
          id: uuid.v4(),
          value: tokenText,
          isExactMatchOnly,
          isRegex,
          isIgnoreWhiteSpace,
        };
        onAddTextToken(tokenGroup, token);
      }
      closeTextTokenModal();
    },
    [
      closeTextTokenModal,
      edittingTextToken,
      tokenGroup,
      onUpdateTextToken,
      onAddTextToken,
    ]
  );

  const onSubmitImageToken = React.useCallback(
    async (name: string, image: File | undefined) => {
      closeImageTokenModal();
      let asset: UploadAssetResponse | undefined;
      if (image) {
        setIsUploadingAsset(true);
        try {
          asset = await uploadAsset(image);
        } catch (e) {
          console.error("Failed to upload asset: ", e);
          return;
        } finally {
          setIsUploadingAsset(false);
        }
      }
      if (edittingImageToken) {
        const token: TokenGroupImage = {
          ...edittingImageToken,
        };
        if (asset) {
          token.assetId = asset.name;
          token.url = asset.url as string;
        }
        onUpdateImageToken(tokenGroup, token);
      } else {
        if (!asset) {
          throw new Error(
            "Unreachable: asset should exist if is creating new image token"
          );
        }
        const token: TokenGroupImage = {
          id: uuid.v4(),
          name,
          assetId: asset.name,
          url: asset.url as string,
        };
        onAddImageToken(tokenGroup, token);
      }
    },
    [
      tokenGroup,
      edittingImageToken,
      closeImageTokenModal,
      onAddImageToken,
      onUpdateImageToken,
    ]
  );

  return (
    <div className={styles["form-group-token-group-content"]}>
      <div className={styles["token-form-name"]}>
        <Text>{tokenGroup.form.name}</Text>
      </div>
      <div className={styles["token-row"]}>
        {tokenGroup.texts.map((textToken, i) => (
          <TextTokenCard
            key={textToken.id}
            textToken={textToken}
            onDelete={handleDeleteTextTokenCallbacks[i]}
            onEditTextToken={onEditTextToken}
          />
        ))}
      </div>
      {hasPermissionToEditResource && (
        <Link className={styles["link"]} onClick={onCreateTextToken}>
          <FormattedMessage id="add.text.token.link" />
        </Link>
      )}

      <div className={classnames([styles["token-row"]])}>
        {tokenGroup.images.map((imageToken, i) => (
          <ImageTokenCard
            key={imageToken.id}
            imageToken={imageToken}
            onRemove={handleImageTokenCallbacks[i]}
            onEditImageToken={onEditImageToken}
          />
        ))}
        {isUploadingAsset && (
          <Spinner
            className={styles["image-token-spinner"]}
            size={SpinnerSize.medium}
          />
        )}
      </div>
      {hasPermissionToEditResource && (
        <Link className={styles["link"]} onClick={onCreateImageToken}>
          <FormattedMessage id="add.image.token.link" />
        </Link>
      )}

      {isTextTokenModalOpen && (
        <TokenGroupTextModal
          tokenTextValidatorWithMessage={validateTextTokenValue}
          isOpen={true}
          onCancel={closeTextTokenModal}
          defaultValue={edittingTextToken}
          onSubmit={onSubmitTextToken}
        />
      )}
      {isImageTokenModalOpen && (
        <ImageTokenModal
          edittingImageToken={edittingImageToken}
          checkIsNameUnique={checkIfImageTokenNameUnique}
          onCancel={closeImageTokenModal}
          onSubmit={onSubmitImageToken}
          isOpen={true}
        />
      )}
    </div>
  );
});
