.grab-mouse-cursor {
  cursor: move; /* fallback if grab cursor is unsupported */
  /* stylelint-disable-next-line */
  cursor: grab;
}

.grab-mouse-cursor:active {
  cursor: move; /* fallback if grab cursor is unsupported */
  /* stylelint-disable-next-line */
  cursor: grabbing;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
}

.single-image-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.single-image-frame-scrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
}
.single-image-frame-scrollable::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.single-image-frame-viewport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-image-frame-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
}

.multiple-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.multiple-image-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.multiple-image-viewport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.multiple-image-scrollable-view {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
}

.multiple-image-scrollable-view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.multiple-image-content-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bottom-overlay-area {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.image-view-container {
  position: relative;
  margin: auto;
}

.image-view-overlay {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image-view-text-area {
  position: absolute;
  border: 1px solid #25d0b1;
  background-color: #25d0b11f;
}

.image-view-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-view-img {
  transform-origin: center center;
}
