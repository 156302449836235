import * as yup from "yup";

import { AdvancedPatternMatching } from "./advancedPatternMatching";
import { pageInfoSchema } from "./pageInfo";

interface ConfigSnapshotInfo {
  name?: string;
  note?: string;
  value?: AdvancedPatternMatching;
}

const basicConfigSnapshotSchema = yup
  .object({
    id: yup.string().defined(),
    info: yup.mixed((_input): _input is ConfigSnapshotInfo => true).defined(),
    createdBy: yup.string().nullable(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),
    bookmarkedBy: yup.string().nullable(),
    bookmarkedAt: yup.string().nullable(),
  })
  .camelCase();

export const configSnapshotSchema = basicConfigSnapshotSchema
  .shape({
    restoreSnapshot: basicConfigSnapshotSchema.nullable(),
  })
  .camelCase();

export const configSnapshotRespSchema = yup
  .object({
    configSnapshot: configSnapshotSchema.required(),
  })
  .camelCase();

export const paginatedConfigSnapshotSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    configSnapshots: yup.array(configSnapshotSchema).defined(),
  })
  .camelCase();

const configSnapshotSearchableItemSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().nullable(),
    createdAt: yup.date().defined(),
  })
  .camelCase();

export const configSnapshotIndexSchema = yup
  .array(configSnapshotSearchableItemSchema)
  .defined();

export type ConfigSnapshot = yup.InferType<typeof configSnapshotSchema>;
export type ConfigSnapshotResp = yup.InferType<typeof configSnapshotRespSchema>;
export type PaginatedConfigSnapshot = yup.InferType<
  typeof paginatedConfigSnapshotSchema
>;
export type ConfigSnapshotSearchableItem = yup.InferType<
  typeof configSnapshotSearchableItemSchema
>;
export type ConfigSnapshotIndex = yup.InferType<
  typeof configSnapshotIndexSchema
>;
