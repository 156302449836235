import { Dialog } from "@fluentui/react";
import ExcelJS from "exceljs";
import * as React from "react";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { useAdvanceTokenSetupEditor } from "../../contexts/advanceTokenSetupEditor";
import { useLocale } from "../../contexts/locale";
import errors from "../../errors";
import { useToast } from "../../hooks/toast";
import { AdvancedPatternMatching } from "../../types/advancedPatternMatching";
import { MenuItem } from "../../types/advancedTokenSetup/table";
import {
  ConfigSnapshot,
  PaginatedConfigSnapshot,
} from "../../types/configSnapshot";
import { ConfirmModalType } from "../../types/confirmation";
import { DetailedForm } from "../../types/form";
import { MerchantPatternMatchingMapper } from "../../types/mappers/advancedPatternMatching/merchant";
import { formatDate } from "../../utils/datetime";
import { ensureFOCRError } from "../../utils/errors";
import { triggerFileSaveFromBlob } from "../../utils/file";
import { tableDiffAsExcel } from "../../utils/tableDiff";
import AdvanceTokenSetupEditor from "../AdvanceTokenSetupEditor";
import { NavTabBar } from "../NavTabBar";
import SnapshotVersionList from "../SnapshotVersionList";
import { dateTimePattern } from "../SnapshotVersionList/config";
import VersionChangesSection from "./VersionChangesSection";

const tabs = [
  {
    key: "version",
    labelId: "advance_token_setup_version_history_modal.tab.version",
  },
  {
    key: "changes",
    labelId: "advance_token_setup_version_history_modal.tab.changes",
  },
];

interface AdvanceTokenSetupVersionHistoryModalRequest {
  form: DetailedForm;
  initialConfigSnapshots?: PaginatedConfigSnapshot;
}

export enum AdvanceTokenSetupVersionHistoryModalResponseType {
  Restored,
  Cancelled,
}

export type AdvanceTokenSetupVersionHistoryModalResponse =
  | {
      type: AdvanceTokenSetupVersionHistoryModalResponseType.Cancelled;
    }
  | {
      type: AdvanceTokenSetupVersionHistoryModalResponseType.Restored;
      acceptedValue: AdvancedPatternMatching;
    };

export function useAdvanceTokenSetupVersionHistoryModalHandle(
  defaultForm?: DetailedForm
) {
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedTabKey, setSelectedTabKey] = React.useState("version");
  const [selectedMenu, setSelectedMenu] = React.useState<MenuItem>(
    MenuItem.TagMerchant
  );
  const onTabChanged = React.useCallback((key: string) => {
    setSelectedTabKey(key);
  }, []);

  const [callback, setCallback] =
    React.useState<
      (
        response: AdvanceTokenSetupVersionHistoryModalResponse
      ) => void | undefined
    >();

  const [baseForm, setBaseForm] = React.useState<DetailedForm | undefined>(
    defaultForm
  );
  const [selectedConfigSnapshot, setSelectedConfigSnapshot] =
    React.useState<ConfigSnapshot>();
  const [isRestoring, setIsRestoring] = React.useState(false);
  const toast = useToast();

  const {
    isDataChanged,
    configSnapshots,
    setConfigSnapshots,
    editConfigSnapshot,
    bookmarkConfigSnapshot,
    deleteConfigSnapshot,
    restoreConfigSnapshot,
    getConfigSnapshot,
    fetchConfigSnapshots,
    fetchConfigSnapshotIndex,
    lastPaginatedConfigSnapshots,
  } = useAdvanceTokenSetupEditor();

  const [isLoading, setIsLoading] = React.useState(false);

  const open = React.useCallback(
    async (
      request: AdvanceTokenSetupVersionHistoryModalRequest
    ): Promise<AdvanceTokenSetupVersionHistoryModalResponse> => {
      setIsOpen(true);
      setSelectedTabKey("version");
      setBaseForm(request.form);
      setSelectedConfigSnapshot(configSnapshots[0]);
      return new Promise(resolve => {
        setCallback(() => resolve);
      });
    },
    [setCallback, configSnapshots]
  );

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    callback?.({
      type: AdvanceTokenSetupVersionHistoryModalResponseType.Cancelled,
    });
  }, [callback]);

  const renewConfigSnapshot = React.useCallback(
    async (configSnapshot: ConfigSnapshot) => {
      const snapshot = await getConfigSnapshot(configSnapshot.id);
      setConfigSnapshots(pre =>
        pre.map(s => (s.id === snapshot.id ? snapshot : s))
      );
    },
    [getConfigSnapshot, setConfigSnapshots]
  );

  const onEditBookmarkedItem = React.useCallback(
    async (
      configSnapshot: ConfigSnapshot,
      newName: string,
      newNote?: string
    ) => {
      try {
        const snapshot = await editConfigSnapshot(
          configSnapshot.id,
          configSnapshot.updatedAt,
          newName,
          newNote
        );
        setConfigSnapshots(p => {
          return p.map(pp => (pp.id === snapshot.id ? snapshot : pp));
        });
      } catch (errorRaw) {
        const error = ensureFOCRError(errorRaw);
        if (error === errors.ConflictFound) {
          toast.error("error.config_snapshot_modifed_prompt.title");
        } else {
          toast.error(error.messageId);
        }
      }
    },
    [editConfigSnapshot, toast, setConfigSnapshots]
  );

  const onBookmarkItem = React.useCallback(
    async (
      configSnapshot: ConfigSnapshot,
      newName: string,
      newNote?: string
    ) => {
      try {
        const snapshot = await bookmarkConfigSnapshot(
          configSnapshot.id,
          configSnapshot.updatedAt,
          newName,
          newNote
        );
        setConfigSnapshots(p => {
          return p.map(pp => (pp.id === snapshot.id ? snapshot : pp));
        });
      } catch (errorRaw) {
        const error = ensureFOCRError(errorRaw);
        if (error === errors.ConfigSnapshotAlreadyBookmarked) {
          renewConfigSnapshot(configSnapshot);
        } else if (error === errors.ConflictFound) {
          toast.error("error.config_snapshot_modifed_prompt.title");
        } else {
          toast.error(error.messageId);
        }
      }
    },
    [bookmarkConfigSnapshot, renewConfigSnapshot, toast, setConfigSnapshots]
  );

  const onDeleteItem = React.useCallback(
    async (configSnapshot: ConfigSnapshot) => {
      try {
        await deleteConfigSnapshot(configSnapshot.id);
        setConfigSnapshots(p => {
          return p.filter(pp => pp.id !== configSnapshot.id);
        });
      } catch (e) {
        toast.error(ensureFOCRError(e).messageId);
      }
    },
    [deleteConfigSnapshot, setConfigSnapshots, toast]
  );

  const onRestoreVersion = React.useCallback(
    (configSnapshot: ConfigSnapshot) => {
      (async () => {
        const confirmed = await requestUserConfirmation(
          {
            titleId: "restore_version_modal.title",
            messageId: isDataChanged
              ? "restore_version_modal.unsaved_form_warning_description_with_restore_name"
              : "restore_version_modal.description_with_restore_name",
            messageValues: {
              restore_name:
                configSnapshot.info.name ??
                formatDate(configSnapshot.createdAt, dateTimePattern),
            },
            type: ConfirmModalType.Destory,
            actionId: isDataChanged
              ? "restore_version_modal.button.discard_and_restore"
              : "restore_version_modal.button.restore",
          },
          false
        );
        if (confirmed && configSnapshot.info.value) {
          try {
            setIsRestoring(true);
            await restoreConfigSnapshot(configSnapshot.id);
            await fetchConfigSnapshots();
            setIsRestoring(false);
            setIsOpen(false);
            callback?.({
              type: AdvanceTokenSetupVersionHistoryModalResponseType.Restored,
              acceptedValue: configSnapshot.info.value,
            });
          } catch (error) {
            toast.error(ensureFOCRError(error).messageId);
          }
        }
      })();
    },
    [
      restoreConfigSnapshot,
      fetchConfigSnapshots,
      requestUserConfirmation,
      isDataChanged,
      callback,
      toast,
    ]
  );
  const onSelectItem = React.useCallback((configSnapshot: ConfigSnapshot) => {
    setSelectedConfigSnapshot(configSnapshot);
  }, []);

  const loadMore = React.useCallback(async () => {
    if (
      lastPaginatedConfigSnapshots &&
      lastPaginatedConfigSnapshots.pageInfo.hasNext
    ) {
      setIsLoading(true);
      try {
        await fetchConfigSnapshots(
          lastPaginatedConfigSnapshots?.pageInfo.cursor
        );
      } catch (e) {
        toast.error(ensureFOCRError(e));
      }
      setIsLoading(false);
    }
  }, [lastPaginatedConfigSnapshots, fetchConfigSnapshots, toast]);

  const onLoadMore = React.useCallback(() => {
    if (isLoading) {
      return;
    }
    loadMore();
  }, [isLoading, loadMore]);

  const fetchConfigSnapshot = React.useCallback(
    async (id: string) => {
      const cachedSnapshot = configSnapshots.find(c => c.id === id);
      if (cachedSnapshot) {
        return cachedSnapshot;
      }
      const r = await getConfigSnapshot(id);
      return r;
    },
    [configSnapshots, getConfigSnapshot]
  );

  const onSelectMenu = React.useCallback((menu: MenuItem) => {
    setSelectedMenu(menu);
  }, []);

  const onDownloadDiffExcel = React.useCallback(
    async (diffOf: { from: ConfigSnapshot; to: ConfigSnapshot }) => {
      if (
        !diffOf.from.info.value?.merchant ||
        !diffOf.to.info.value?.merchant
      ) {
        return;
      }
      const [, fromData] = MerchantPatternMatchingMapper.fromStorage(
        diffOf.from.info.value.merchant
      );
      const [, toData] = MerchantPatternMatchingMapper.fromStorage(
        diffOf.to.info.value.merchant
      );
      const mergedWorkbook = new ExcelJS.Workbook();
      for (const item of Object.values(MenuItem)) {
        const fromTable = MerchantPatternMatchingMapper.toCSV(
          item,
          fromData[item],
          {
            distinctHeader: true,
          }
        );
        const toTable = MerchantPatternMatchingMapper.toCSV(
          item,
          toData[item],
          {
            distinctHeader: true,
          }
        );
        tableDiffAsExcel(fromTable, toTable, {
          workbook: mergedWorkbook,
          sheetName: item.toString(),
        });
      }
      const data = await mergedWorkbook.xlsx.writeBuffer();
      const toName =
        diffOf.to.info.name ?? formatDate(diffOf.to.createdAt, dateTimePattern);
      const fromName =
        diffOf.from.info.name ??
        formatDate(diffOf.from.createdAt, dateTimePattern);
      const filename = `Advanced-pattern-matching-version-difference-of-${toName}-${fromName}.xlsx`;
      triggerFileSaveFromBlob(new Blob([data]), filename);
    },
    []
  );

  const triggerProps = React.useMemo(() => {
    return {
      isOpen,
      isRestoring,
      form: baseForm,
      configSnapshots,
      onCancel: onClose,
      onEditBookmarkedItem,
      onBookmarkItem,
      onRestoreVersion,
      onDeleteItem,
      onSelectItem,
      onLoadMore,
      hasLoadMore: lastPaginatedConfigSnapshots?.pageInfo.hasNext ?? false,
      selectedConfigSnapshot,
      isLoading,
      fetchConfigSnapshot,
      fetchConfigSnapshotIndex,
      onDownloadDiffExcel,
      selectedTabKey,
      onTabChanged,
      selectedMenu,
      onSelectMenu,
    };
  }, [
    isOpen,
    isRestoring,
    baseForm,
    configSnapshots,
    onClose,
    onEditBookmarkedItem,
    onBookmarkItem,
    onRestoreVersion,
    onDeleteItem,
    onSelectItem,
    onLoadMore,
    selectedConfigSnapshot,
    lastPaginatedConfigSnapshots?.pageInfo.hasNext,
    isLoading,
    fetchConfigSnapshot,
    fetchConfigSnapshotIndex,
    onDownloadDiffExcel,
    selectedTabKey,
    onTabChanged,

    selectedMenu,
    onSelectMenu,
  ]);
  return React.useMemo(() => {
    return {
      open,
      triggerProps,
    };
  }, [open, triggerProps]);
}

type Props = ReturnType<
  typeof useAdvanceTokenSetupVersionHistoryModalHandle
>["triggerProps"];

const AdvanceTokenSetupVersionHistoryModal = React.memo((props: Props) => {
  const {
    form,
    selectedConfigSnapshot,
    isOpen,
    isRestoring,
    onCancel,
    configSnapshots,
    onRestoreVersion,
    onDeleteItem,
    onBookmarkItem,
    onEditBookmarkedItem,
    onSelectItem,
    hasLoadMore,
    onLoadMore,
    fetchConfigSnapshot,
    fetchConfigSnapshotIndex,
    onDownloadDiffExcel,
    selectedTabKey,
    onTabChanged,
    onSelectMenu,
    selectedMenu,
  } = props;

  const { localized } = useLocale();

  const previousLayerStyle = React.useRef<string | null>();

  const onLayerMounted = React.useCallback(() => {
    //NOTE: make toast message visible on top of modal
    previousLayerStyle.current = document
      .getElementById("fluent-default-layer-host")
      ?.getAttribute("style");
    document
      .getElementById("fluent-default-layer-host")
      ?.setAttribute("style", "position:fixed;z-index:999");
  }, []);

  const onLayerWillUnmount = React.useCallback(() => {
    if (previousLayerStyle.current) {
      document
        .getElementById("fluent-default-layer-host")
        ?.setAttribute("style", previousLayerStyle.current);
    }
  }, []);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    const hashName = "#versions";
    window.history.pushState(
      hashName,
      "Version history",
      document.location + hashName
    );

    window.addEventListener("popstate", onCancel);

    return () => {
      if (window.history.state === hashName) {
        history.go(-1);
      }
      window.removeEventListener("popstate", onCancel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={{
        layerProps: {
          eventBubblingEnabled: true,
          onLayerMounted,
          onLayerWillUnmount,
        },
      }}
      dialogContentProps={{
        title: localized("advance_token_setup_version_history_modal.title"),
        showCloseButton: true,
        styles: {
          innerContent: {
            padding: 0,
          },
          inner: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            padding: 0,
          },
        },
      }}
      maxWidth={"100vw"}
    >
      <div className="flex flex-col w-[calc(100vw-96px)] h-[calc(100vh-158px)]  flex-1 border-t-[#EDEBE9] border-t-1 ">
        <div className="mx-4 mb-2">
          <NavTabBar
            tabs={tabs}
            selectedKey={selectedTabKey}
            onSelect={onTabChanged}
          />
        </div>
        {selectedTabKey === "changes" ? (
          <VersionChangesSection
            fetchConfigSnapshotIndex={fetchConfigSnapshotIndex}
            fetchConfigSnapshot={fetchConfigSnapshot}
            onDownloadDiffExcel={onDownloadDiffExcel}
            selectedMenu={selectedMenu}
            onSelectMenu={onSelectMenu}
            defaultToConfigSnapshotId={selectedConfigSnapshot?.id}
          />
        ) : (
          <div className="flex flex-1 flex-row overflow-hidden">
            <AdvanceTokenSetupEditor
              className="flex-1 overflow-hidden"
              form={form}
              isReadOnly={true}
              visibilitySetting={{
                toolbar: false,
                breadcrumbNavBar: false,
                footer: false,
              }}
              previewConfig={selectedConfigSnapshot?.info.value?.merchant ?? {}}
              selectedMenu={selectedMenu}
              onSelectMenu={onSelectMenu}
            />
            <SnapshotVersionList
              items={configSnapshots}
              selectedSnapshotId={selectedConfigSnapshot?.id}
              onEditBookmarkedItem={onEditBookmarkedItem}
              onBookmarkItem={onBookmarkItem}
              onDeleteItem={onDeleteItem}
              onRestoreVersion={onRestoreVersion}
              onSelectItem={onSelectItem}
              onLoadMore={onLoadMore}
              hasLoadMore={hasLoadMore}
              isRestoring={isRestoring}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default AdvanceTokenSetupVersionHistoryModal;
