import { IContextualMenuItem, Spinner, SpinnerSize } from "@fluentui/react";
import classNames from "classnames";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { useLocale } from "../../contexts/locale";
import { ConfigSnapshot } from "../../types/configSnapshot";
import { ConfirmModalType } from "../../types/confirmation";
import {
  formatDate,
  isToday,
  isYesterday,
  parseISO,
} from "../../utils/datetime";
import BookmarkSnapshotModal, {
  BookmarkResponseType,
  useBookmarkSnapshotModalHandle,
} from "../BookmarkSnapshotModal";
import { IconButton } from "../IconButton";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import SnapshotVersionItem from "./Item";
import { dateFormatPattern, dateTimePattern } from "./config";

interface Props {
  className?: string;
  items: ConfigSnapshot[];
  selectedSnapshotId?: string;
  onEditBookmarkedItem: (
    configSnapshot: ConfigSnapshot,
    newName: string,
    newNote?: string
  ) => void;
  onBookmarkItem: (
    configSnapshot: ConfigSnapshot,
    name: string,
    note?: string
  ) => void;
  onDeleteItem: (configSnapshot: ConfigSnapshot) => void;
  onRestoreVersion: (configSnapshot: ConfigSnapshot) => void;
  onSelectItem: (configSnapshot: ConfigSnapshot) => void;
  isRestoring?: boolean;
  hasLoadMore: boolean;
  onLoadMore: () => void;
}

const SnapshotVersionList = React.memo((props: Props) => {
  const {
    className,
    items,
    onRestoreVersion,
    onDeleteItem,
    onEditBookmarkedItem,
    onBookmarkItem,
    onSelectItem,
    isRestoring,
    hasLoadMore,
    onLoadMore,
    selectedSnapshotId,
  } = props;
  const { localized } = useLocale();

  const title = localized("snapshot_version_list.title");
  const [selectedFilterKey, setSelectedFilterKey] =
    React.useState<string>("filter_by_all");
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const {
    open: openBookmarkSnapshotModal,
    triggerProps: bookmarkSnapshotModalTriggerProps,
  } = useBookmarkSnapshotModalHandle();

  const filteredItems = React.useMemo(() => {
    return selectedFilterKey === "filter_by_all"
      ? items
      : items.filter(item => item.bookmarkedAt);
  }, [items, selectedFilterKey]);

  const groupsByDate = React.useMemo(() => {
    const groups: Record<string, ConfigSnapshot[]> = {};
    const keyToday = localized("snapshot_version_list.date_group.today");
    const keyYesterday = localized(
      "snapshot_version_list.date_group.yesterday"
    );
    const dates = filteredItems.map(item => parseISO(item.createdAt));
    let index = 0;
    for (const date of dates) {
      const item = filteredItems[index];
      index++;
      let key: string;
      if (isToday(date)) {
        key = keyToday;
      } else if (isYesterday(date)) {
        key = keyYesterday;
      } else {
        key = formatDate(date, dateFormatPattern);
      }
      if (key in groups) {
        groups[key] = groups[key].concat(item);
      } else {
        groups[key] = [item];
      }
    }
    return groups;
  }, [filteredItems, localized]);

  const filterMenus: IContextualMenuItem[] = React.useMemo(() => {
    return [
      {
        key: "filter_by_all",
        text: localized("snapshot_version_list.item.action.filter_by_all"),
        canCheck: true,
        checked: selectedFilterKey === "filter_by_all",
      },
      {
        key: "filter_by_bookmark",
        text: localized(
          "snapshot_version_list.item.action.filter_by_bookmarked"
        ),
        canCheck: true,
        checked: selectedFilterKey === "filter_by_bookmark",
      },
    ];
  }, [localized, selectedFilterKey]);

  const restoreConfigSnapshot = React.useMemo(() => {
    return items.find(item => item.id === selectedSnapshotId);
  }, [selectedSnapshotId, items]);

  const onFilterChanged = React.useCallback(
    (
      _ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
      item?: IContextualMenuItem
    ) => {
      if (item) {
        setSelectedFilterKey(item.key);
      }
    },
    []
  );

  const onItemPressed = React.useCallback(
    (item: ConfigSnapshot) => {
      onSelectItem(item);
    },
    [onSelectItem]
  );

  const onRestorePressed = React.useCallback(() => {
    (async () => {
      const configSnapshot = restoreConfigSnapshot;
      if (!configSnapshot) {
        return;
      }
      onRestoreVersion(restoreConfigSnapshot);
    })();
  }, [restoreConfigSnapshot, onRestoreVersion]);

  const onDeletePressed = React.useCallback(
    (c: ConfigSnapshot) => {
      (async () => {
        try {
          await requestUserConfirmation({
            titleId: "delete_version_modal.title",
            messageId: "delete_version_modal.description_with_name",
            messageValues: {
              name: c.info.name ?? formatDate(c.createdAt, dateTimePattern),
            },
            type: ConfirmModalType.Destory,
            actionId: "delete_version_modal.button.delete",
          });
          onDeleteItem(c);
        } catch {
          // cancel
        }
      })();
    },
    [requestUserConfirmation, onDeleteItem]
  );

  const onEditInfoPressed = React.useCallback(
    async (c: ConfigSnapshot) => {
      const r = await openBookmarkSnapshotModal({
        defaultTitle: c.info.name,
        defaultNote: c.info.note,
      });
      if (r.type === BookmarkResponseType.Accepted) {
        const { title, note } = r.acceptedValue;
        onEditBookmarkedItem(c, title, note);
      }
    },
    [openBookmarkSnapshotModal, onEditBookmarkedItem]
  );
  const onBookmarkPressed = React.useCallback(
    async (c: ConfigSnapshot) => {
      const r = await openBookmarkSnapshotModal();
      if (r.type === BookmarkResponseType.Accepted) {
        const { title, note } = r.acceptedValue;
        onBookmarkItem(c, title, note);
      }
    },
    [openBookmarkSnapshotModal, onBookmarkItem]
  );

  const scrollContentParentRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div className={classNames("flex flex-col w-[340px]", className)}>
      <div className="flex flex-row px-6 items-center">
        <p className="flex-1 pt-5 pb-3.5 font-semibold text-xl">{title}</p>
        <IconButton
          iconProps={{
            color: "#605E5C",
            iconName: "Filter",
          }}
          menuProps={{
            items: filterMenus,
            onItemClick: onFilterChanged,
          }}
          onRenderMenuIcon={() => <div />}
        />
      </div>
      <div
        ref={r => (scrollContentParentRef.current = r)}
        className="flex flex-1 flex-col overflow-y-auto"
      >
        <InfiniteScroll
          start={0}
          loadMore={onLoadMore}
          hasMore={hasLoadMore}
          useWindow={false}
          getScrollParent={() => scrollContentParentRef.current}
          loader={
            <Spinner key="spinner" className="my-2" size={SpinnerSize.medium} />
          }
        >
          {Object.keys(groupsByDate).map(key => {
            return (
              <li className="list-none" key={key}>
                <p className="px-6 pt-2 pb-2 text-[#605E5C] font-semibold text-sm">
                  {key}
                </p>
                <div>
                  {groupsByDate[key].map(item => (
                    <SnapshotVersionItem
                      key={item.id}
                      onDeletePressed={onDeletePressed}
                      onEditInfoPressed={onEditInfoPressed}
                      onBookmarkPressed={onBookmarkPressed}
                      isSelected={item.id === selectedSnapshotId}
                      onPressed={onItemPressed}
                      item={item}
                    />
                  ))}
                </div>
              </li>
            );
          })}
        </InfiniteScroll>
      </div>
      <div className="flex px-6 py-4 justify-end">
        <PrimaryButton
          textId="snapshot_version_list.action.restore"
          loading={isRestoring}
          disabled={selectedSnapshotId === undefined}
          onClick={onRestorePressed}
        />
      </div>
      <BookmarkSnapshotModal {...bookmarkSnapshotModalTriggerProps} />
    </div>
  );
});

export default SnapshotVersionList;
