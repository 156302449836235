@import "../../styles/variables.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
}

.section {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid $neutral-light;
  display: grid;
  grid-template-columns: 485px;
  row-gap: 10px;
}

.section-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.section-header-title {
  color: #323130;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}

.section-header-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.section-header-paragraph {
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  a {
    color: #53b2a1;
  }
}

.section-header-paragraph-button {
  color: #0078d4;
  cursor: pointer;
}

.workspace-extractor {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #faf9f8;
}

.workspace-extractor-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  word-break: break-word;
}

.workspace-extractor-name {
  color: #201f1e;
}

.workspace-extractor-id {
  color: #605e5c;
}

.workspace-go-to-extractor {
  color: #0078d4;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
}

.workspace-go-to-extractor-icon {
  color: #0078d4;
  margin-left: 6px;
}

.section-field-question-label {
  display: inline-block;
}

.section-field-question-read-more {
  margin-left: 8px;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px 30px;
  background-color: white;
}

.footer-separator {
  height: 1px;
  background-color: $separator-color-2;
  margin-bottom: 11px;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
}

.read-more-button__container {
  display: inline-block;
  cursor: pointer;
}

.read-more-button__label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.read-more-button__text {
  color: #0078d4;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.read-more-button__icon {
  color: #0078d4;
  width: 14px;
  height: 14px;
}

.document-file-label__container {
  padding-left: 26px;
}

.document-file-label__text {
  color: #201f1e;
}

.document-file-label__description {
  color: #a19f9d;
}
