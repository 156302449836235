import classnames from "classnames";
import React, { useState } from "react";

import { ExtractAPIV2Document } from "../../models";
import { PreferenceKey, getPreference } from "../../utils/preference";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import { ResultAssetViewer, ViewMode } from "./ResultAssetViewer";
import styles from "./styles.module.scss";

interface ViewModeActionButtonData {
  iconName: string;
  textId: string;
  viewMode: ViewMode;
}

interface ViewModeActionButtonProps {
  iconName: string;
  textId: string;
  onClick: () => void;
  isActive: boolean;
}

function ViewModeActionButton(props: ViewModeActionButtonProps) {
  return (
    <div className="last:pr-[48px]">
      <ActionButton
        iconName={props.iconName}
        textId={props.textId}
        onClick={props.onClick}
      />
      <hr
        className={classnames("border-0 h-[2px] bg-primary-200", {
          hidden: !props.isActive,
        })}
      />
    </div>
  );
}

interface ResultAssetSectionProps {
  resultAsset: ExtractAPIV2Document;
  assetUrl?: string;
  onDownloadClick: () => void;
  onClickReExtract: () => void;
  canReExtract: boolean;
}

export function ResultAssetSection(props: ResultAssetSectionProps) {
  const {
    resultAsset,
    onDownloadClick,
    assetUrl,
    onClickReExtract,
    canReExtract,
  } = props;

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.KeyValue);

  const isImageQualityExists = resultAsset.metadata?.image_quality != null;

  const tabs = React.useMemo(() => {
    const _tabs: ViewModeActionButtonData[] = [];
    _tabs.push({
      iconName: "Table",
      textId: "workspace.document_detail.key_value",
      viewMode: ViewMode.KeyValue,
    });

    _tabs.push({
      iconName: "Code",
      textId: "workspace.document_detail.json",
      viewMode: ViewMode.Json,
    });

    if (isImageQualityExists) {
      _tabs.push({
        iconName: "Photo2",
        textId: "workspace.document_detail.image_quality",
        viewMode: ViewMode.ImageQuality,
      });
    }
    return _tabs;
  }, [isImageQualityExists]);

  return (
    <>
      <div className={styles.viewOptionButtonGroup}>
        <div className="flex flex-row pr-2">
          {tabs.map(tab => (
            <ViewModeActionButton
              key={tab.viewMode}
              iconName={tab.iconName}
              textId={tab.textId}
              onClick={() => setViewMode(tab.viewMode)}
              isActive={viewMode === tab.viewMode}
            />
          ))}
        </div>
        <div className="flex flex-row">
          <ActionButton
            iconName="Download"
            textId="workspace.document_detail.download_button.label"
            onClick={onDownloadClick}
          />
          {canReExtract &&
          getPreference(PreferenceKey.allowReExtractSuccessResult) ===
            "true" ? (
            <ActionButton
              iconName="Refresh"
              textId="workspace.document_detail.retry_extraction"
              onClick={onClickReExtract}
            />
          ) : null}
        </div>
      </div>

      <ResultAssetViewer
        resultAsset={resultAsset}
        viewMode={viewMode}
        assetUrl={assetUrl}
      />
    </>
  );
}
