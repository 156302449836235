import {
  ConfigSnapshot,
  ConfigSnapshotIndex,
  PaginatedConfigSnapshot,
  configSnapshotIndexSchema,
  configSnapshotSchema,
  paginatedConfigSnapshotSchema,
} from "../../types/configSnapshot";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface ConfigSnapshotApiClient {
  listConfigSnapshots: (
    entityType: "form" | "form_group" | "custom_model",
    entityId: string,
    key: string,
    size: number,
    cursor?: string,
    resourceOwnerId?: string
  ) => Promise<PaginatedConfigSnapshot>;
  getConfigSnapshot: (
    configSnapshotId: string,
    resourceOwnerId?: string
  ) => Promise<ConfigSnapshot>;
  editConfigSnapshotInfo: (
    configSnapshotId: string,
    retrievedAt: string,
    shouldOverwrite: boolean,
    name: string,
    note?: string,
    resourceOwnerId?: string
  ) => Promise<ConfigSnapshot>;
  bookmarkConfigSnapshot: (
    configSnapshotId: string,
    retrievedAt: string,
    shouldOverwrite: boolean,
    name: string,
    note?: string,
    resourceOwnerId?: string
  ) => Promise<ConfigSnapshot>;
  deleteConfigSnapshot: (
    configSnapshotId: string,

    resourceOwnerId?: string
  ) => Promise<void>;
  listConfigSnapshotIndex: (
    entityType: "form" | "form_group" | "custom_model",
    entityId: string,
    key: string,
    resourceOwnerId?: string
  ) => Promise<ConfigSnapshotIndex>;
}

export function withConfigSnapshotApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base implements ConfigSnapshotApiClient {
    async listConfigSnapshots(
      entityType: "form" | "form_group" | "custom_model",
      entityId: string,
      key: string,
      size: number,
      cursor?: string,
      resourceOwnerId?: string
    ): Promise<PaginatedConfigSnapshot> {
      const args = {
        page_args: {
          cursor: cursor ?? "",
          size,
        },
        entity_id: entityId,
        entity_type: entityType,
        key,
        resource_owner_id: resourceOwnerId,
      };
      return this.lambda(
        "config_snapshot:list",
        args,
        paginatedConfigSnapshotSchema
      );
    }

    async getConfigSnapshot(
      configSnapshotId: string,
      resourceOwnerId?: string
    ): Promise<ConfigSnapshot> {
      return this.lambda(
        "config_snapshot:get",
        {
          config_snapshot_id: configSnapshotId,
          resource_owner_id: resourceOwnerId,
        },
        configSnapshotSchema
      );
    }

    async editConfigSnapshotInfo(
      configSnapshotId: string,
      retrievedAt: string,
      shouldOverwrite: boolean,
      name: string,
      note?: string,
      resourceOwnerId?: string
    ): Promise<ConfigSnapshot> {
      return this.lambda(
        "config_snapshot:update",
        {
          config_snapshot_id: configSnapshotId,
          name,
          note,
          resource_owner_id: resourceOwnerId,
          retrieved_at: retrievedAt,
          should_overwrite: shouldOverwrite,
        },
        configSnapshotSchema
      );
    }
    async bookmarkConfigSnapshot(
      configSnapshotId: string,
      retrievedAt: string,
      shouldOverwrite: boolean,
      name: string,
      note?: string,
      resourceOwnerId?: string
    ): Promise<ConfigSnapshot> {
      return this.lambda(
        "config_snapshot:bookmark",
        {
          config_snapshot_id: configSnapshotId,
          name,
          note,
          resource_owner_id: resourceOwnerId,
          retrieved_at: retrievedAt,
          should_overwrite: shouldOverwrite,
        },
        configSnapshotSchema
      );
    }
    async deleteConfigSnapshot(
      configSnapshotId: string,

      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda("config_snapshot:delete", {
        config_snapshot_id: configSnapshotId,
        resource_owner_id: resourceOwnerId,
      });
    }
    async listConfigSnapshotIndex(
      entityType: "form" | "form_group" | "custom_model",
      entityId: string,
      key: string,
      resourceOwnerId?: string
    ): Promise<ConfigSnapshotIndex> {
      return this.lambda(
        "config_snapshot:index",
        {
          entity_id: entityId,
          entity_type: entityType,
          key,
          resource_owner_id: resourceOwnerId,
        },
        configSnapshotIndexSchema
      );
    }
  };
}
