import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { useToast } from "../../hooks/toast";
import { MenuItem } from "../../types/advancedTokenSetup/table";
import {
  ConfigSnapshot,
  ConfigSnapshotSearchableItem,
} from "../../types/configSnapshot";
import { MerchantPatternMatchingMapper } from "../../types/mappers/advancedPatternMatching/merchant";
import { ensureFOCRError } from "../../utils/errors";
import {
  TableDiffDescription,
  emptyTableDiffDescription,
  tableDiff,
  tableDiffAsDescription,
} from "../../utils/tableDiff";
import AdvanceTokenSetupNavMenu from "../AdvanceTokenSetupNavMenu";
import SnapshotVersionChangeLogs from "../SnapshotVersionChangeLogs";
import SnapshotVersionDiffTable from "../SnapshotVersionDiffTable";

interface useVersionChangesSectionProps {
  fetchConfigSnapshotIndex: () => Promise<ConfigSnapshotSearchableItem[]>;
  fetchConfigSnapshot: (id: string) => Promise<ConfigSnapshot>;
  selectedMenu?: MenuItem;
  onSelectMenu: (item: MenuItem) => void;
  defaultFromConfigSnapshotId?: string;
  defaultToConfigSnapshotId?: string;
  onDownloadDiffExcel: (diffOf: {
    from: ConfigSnapshot;
    to: ConfigSnapshot;
  }) => void;
}

export function useVersionChangesSectionState(
  props: useVersionChangesSectionProps
) {
  const {
    fetchConfigSnapshotIndex: fetchConfigSnapshotIndexImpl,
    fetchConfigSnapshot,
    onDownloadDiffExcel,
    defaultToConfigSnapshotId,
    defaultFromConfigSnapshotId,
    selectedMenu = MenuItem.TagMerchant,
    onSelectMenu,
  } = props;

  const [isLoadingSearchableItems, setIsLoadingSearchableItems] =
    useState(false);
  const [searchableItems, setSearchableItems] = useState<
    ConfigSnapshotSearchableItem[]
  >([]);

  const [selectedFrom, setSelectedFrom] =
    useState<ConfigSnapshotSearchableItem>();
  const [selectedTo, setSelectedTo] = useState<ConfigSnapshotSearchableItem>();

  const fromVersionOptions = React.useMemo(() => {
    if (!selectedTo) {
      return searchableItems;
    }
    return searchableItems.filter(
      o => o.id !== selectedTo.id && o.createdAt <= selectedTo.createdAt
    );
  }, [searchableItems, selectedTo]);

  const toVersionOptions = React.useMemo(() => {
    if (!selectedFrom) {
      return searchableItems;
    }
    return searchableItems.filter(
      o => o.id !== selectedFrom.id && o.createdAt > selectedFrom.createdAt
    );
  }, [searchableItems, selectedFrom]);

  const [fromConfigSnapshot, setFromConfigSnapshot] =
    useState<ConfigSnapshot>();
  const [toConfigSnapshot, setToConfigSnapshot] = useState<ConfigSnapshot>();
  const { localized } = useLocale();
  const toast = useToast();

  const fromData = React.useMemo(() => {
    if (!fromConfigSnapshot?.info) {
      return;
    }
    const [, data] = MerchantPatternMatchingMapper.fromStorage(
      fromConfigSnapshot.info.value?.merchant ?? {}
    );
    const d: Record<MenuItem, string[][]> = {
      TagMall: [],
      TagMerchant: [],
      ExactMatchRule: [],
      FieldReplacementMall: [],
      FieldReplacementMerchant: [],
      ClosestMatchRuleMall: [],
      ClosestMatchRuleMerchant: [],
    };
    for (const item of Object.values(MenuItem)) {
      d[item] = MerchantPatternMatchingMapper.toCSV(item, data[item], {
        distinctHeader: true,
      });
    }
    return d;
  }, [fromConfigSnapshot]);
  const toData = React.useMemo(() => {
    if (!toConfigSnapshot?.info) {
      return;
    }
    const [, data] = MerchantPatternMatchingMapper.fromStorage(
      toConfigSnapshot.info.value?.merchant ?? {}
    );
    const d: Record<MenuItem, string[][]> = {
      TagMall: [],
      TagMerchant: [],
      ExactMatchRule: [],
      FieldReplacementMall: [],
      FieldReplacementMerchant: [],
      ClosestMatchRuleMall: [],
      ClosestMatchRuleMerchant: [],
    };
    for (const item of Object.values(MenuItem)) {
      d[item] = MerchantPatternMatchingMapper.toCSV(item, data[item], {
        distinctHeader: true,
      });
    }
    return d;
  }, [toConfigSnapshot]);

  const diffTable = useMemo(() => {
    if (!fromData || !toData) {
      return;
    }
    return tableDiff(fromData[selectedMenu], toData[selectedMenu]);
  }, [fromData, toData, selectedMenu]);

  const tableSummaryGroups = useMemo(() => {
    if (!fromData || !toData) {
      return [];
    }
    const diffSummary: Record<MenuItem, TableDiffDescription> = {
      TagMall: emptyTableDiffDescription,
      TagMerchant: emptyTableDiffDescription,
      ExactMatchRule: emptyTableDiffDescription,
      FieldReplacementMall: emptyTableDiffDescription,
      FieldReplacementMerchant: emptyTableDiffDescription,
      ClosestMatchRuleMall: emptyTableDiffDescription,
      ClosestMatchRuleMerchant: emptyTableDiffDescription,
    };
    for (const item of Object.values(MenuItem)) {
      diffSummary[item] = tableDiffAsDescription(fromData[item], toData[item]);
    }
    return [
      {
        name: localized(
          "version_changes_section.diff_log_group_name.tag_group"
        ),
        tables: [
          {
            name: localized(
              "version_changes_section.diff_log_table_name.merchant_id"
            ),
            description: diffSummary.TagMerchant,
          },
          {
            name: localized(
              "version_changes_section.diff_log_table_name.mall_id"
            ),
            description: diffSummary.TagMall,
          },
        ],
      },
      {
        name: localized(
          "version_changes_section.diff_log_group_name.exact_match_rule"
        ),
        tables: [
          {
            description: diffSummary.ExactMatchRule,
          },
        ],
      },
      {
        name: localized(
          "version_changes_section.diff_log_group_name.closest_match_rule"
        ),
        tables: [
          {
            name: localized(
              "version_changes_section.diff_log_table_name.merchant_id"
            ),
            description: diffSummary.ClosestMatchRuleMerchant,
          },
          {
            name: localized(
              "version_changes_section.diff_log_table_name.mall_id"
            ),
            description: diffSummary.ClosestMatchRuleMall,
          },
        ],
      },
      {
        name: localized(
          "version_changes_section.diff_log_group_name.field_replacement"
        ),
        tables: [
          {
            name: localized(
              "version_changes_section.diff_log_table_name.merchant_id"
            ),
            description: diffSummary.FieldReplacementMerchant,
          },
          {
            name: localized(
              "version_changes_section.diff_log_table_name.mall_id"
            ),
            description: diffSummary.FieldReplacementMall,
          },
        ],
      },
    ]
      .map(g => ({
        ...g,
        tables: g.tables.filter(
          t =>
            t.description.added > 0 ||
            t.description.removed > 0 ||
            t.description.updated > 0
        ),
      }))
      .filter(g => g.tables.length > 0);
  }, [fromData, toData, localized]);

  const onDownloadExcel = React.useCallback(
    (from: ConfigSnapshot, to: ConfigSnapshot) => {
      onDownloadDiffExcel({
        from,
        to,
      });
    },
    [onDownloadDiffExcel]
  );

  const onSelectFromVersion = useCallback(
    async (item: ConfigSnapshotSearchableItem) => {
      setSelectedFrom(item);
      const r = await fetchConfigSnapshot(item.id);
      setFromConfigSnapshot(r);
    },
    [fetchConfigSnapshot]
  );

  const onSelectToVersion = useCallback(
    async (item: ConfigSnapshotSearchableItem) => {
      setSelectedTo(item);
      const r = await fetchConfigSnapshot(item.id);
      setToConfigSnapshot(r);
    },
    [fetchConfigSnapshot]
  );

  const fetchConfigSnapshotIndex = useCallback(async () => {
    try {
      setIsLoadingSearchableItems(true);
      const snapshotIndex = await fetchConfigSnapshotIndexImpl();
      setSearchableItems(snapshotIndex);
      const toVersion =
        snapshotIndex.find(c => c.id === defaultToConfigSnapshotId) ||
        snapshotIndex[0];
      const fromVersion =
        snapshotIndex.find(
          c => c.id === defaultFromConfigSnapshotId && c.id !== toVersion.id
        ) ||
        // first named version snapshot
        snapshotIndex.find(
          c =>
            c.id !== toVersion.id && c.name && c.createdAt < toVersion.createdAt
        ) ||
        // first nearest snapshot
        snapshotIndex.find(
          c => c.id !== toVersion.id && c.createdAt < toVersion.createdAt
        );
      if (fromVersion) {
        onSelectFromVersion(fromVersion);
      }
      if (toVersion) {
        onSelectToVersion(toVersion);
      }
    } catch (e) {
      toast.error(ensureFOCRError(e));
    }
    setIsLoadingSearchableItems(false);
  }, [
    defaultFromConfigSnapshotId,
    defaultToConfigSnapshotId,
    fetchConfigSnapshotIndexImpl,
    onSelectFromVersion,
    onSelectToVersion,
    toast,
  ]);
  useEffect(() => {
    fetchConfigSnapshotIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.useMemo(() => {
    return {
      diffTable,
      selectedMenu,
      onMenuItemClick: onSelectMenu,
      onDownloadExcel,
      onSelectFromVersion,
      onSelectToVersion,
      fromVersionOptions,
      toVersionOptions,
      tableSummaryGroups,
      fromConfigSnapshot,
      toConfigSnapshot,
      selectedFrom,
      selectedTo,
      isLoadingSearchableItems,
    };
  }, [
    diffTable,
    selectedMenu,
    onDownloadExcel,
    onSelectFromVersion,
    onSelectToVersion,
    fromVersionOptions,
    toVersionOptions,
    tableSummaryGroups,
    fromConfigSnapshot,
    toConfigSnapshot,
    selectedFrom,
    selectedTo,
    onSelectMenu,
    isLoadingSearchableItems,
  ]);
}

type Props = ReturnType<typeof useVersionChangesSectionState>;

const VersionChangesSectionImpl = React.memo((props: Props) => {
  const {
    diffTable,
    selectedMenu,
    onMenuItemClick,
    onDownloadExcel,
    onSelectFromVersion,
    onSelectToVersion,
    fromVersionOptions,
    toVersionOptions,
    tableSummaryGroups,
    fromConfigSnapshot,
    toConfigSnapshot,
    selectedFrom,
    selectedTo,
    isLoadingSearchableItems,
  } = props;
  return (
    <div className="flex flex-1 flex-row overflow-hidden">
      <div className="overflow-y-auto min-w-[237px]">
        <AdvanceTokenSetupNavMenu
          selectedMenuItem={selectedMenu}
          onMenuItemClick={onMenuItemClick}
        />
      </div>
      <div className="flex flex-1 overflow-x-auto">
        {diffTable && <SnapshotVersionDiffTable diffTable={diffTable} />}
      </div>
      <SnapshotVersionChangeLogs
        className="max-w-[340px]"
        onDownloadExcel={onDownloadExcel}
        fromVersionOptions={fromVersionOptions}
        toVersionOptions={toVersionOptions}
        onSelectFromVersion={onSelectFromVersion}
        onSelectToVersion={onSelectToVersion}
        tableSummaryGroups={tableSummaryGroups}
        selectedFromVersion={fromConfigSnapshot}
        selectedToVersion={toConfigSnapshot}
        selectedFromVersionKey={selectedFrom?.id}
        selectedToVersionKey={selectedTo?.id}
        isLoadingVersions={isLoadingSearchableItems}
      />
    </div>
  );
});

const VersionChangesSection = React.memo(
  (props: useVersionChangesSectionProps) => {
    const state = useVersionChangesSectionState(props);
    return <VersionChangesSectionImpl {...state} />;
  }
);

export default VersionChangesSection;
