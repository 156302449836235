@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";
$container-height: 75%;

.container {
  position: fixed;
  padding: 0px 0px;
  height: $container-height;
  max-height: $container-height;
  width: 0px;
  bottom: 0px;
  z-index: 100;
  transition: bottom 0.3s ease;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background: #faf9f8;
}

.container-closed {
  position: fixed;
  height: $container-height;
}

.z-layout {
  position: relative;
  height: 100%;
  width: 100%;
}

.table-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.table {
  width: 100%;
  height: 100%;
}
.drag-handle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 6px;
  cursor: ns-resize;
}

.drag-handle:hover {
  border-top: 1px solid $theme-primary;
}
