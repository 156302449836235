import {
  ChoiceGroup,
  IChoiceGroupOption,
  Icon,
  Label,
  Link,
  Text,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { DocumentExportFlattenOption, Workspace } from "../../types/workspace";
import { CopyBlock } from "../CopyBlock";
import { DangerButton } from "../DangerButton";
import DataNotSavedPrompt from "../DataNotSavedPrompt";
import styles from "./styles.module.scss";

function ReadMoreButton(props: {
  className?: string;
  labelId?: string;
  onClick?: () => void;
}) {
  const { className, labelId, onClick } = props;
  return (
    <div
      className={cn(styles["read-more-button__container"], className)}
      onClick={onClick}
    >
      <div className={styles["read-more-button__label"]}>
        <Text className={styles["read-more-button__text"]}>
          <FormattedMessage id={labelId ?? "common.read_more"} />
        </Text>
        <Icon className={styles["read-more-button__icon"]} iconName="Unknown" />
      </div>
    </div>
  );
}

export type OnSaveWorkspaceInput = {
  name: string;
  documentExportFlattenOption: DocumentExportFlattenOption;
};

interface WorkspaceSettingSectionProps {
  workspace: Workspace;
  onConnectedExtractorClick: (
    workspace: Workspace,
    isToSettingPage?: boolean
  ) => void;
  onReadMoreOnMultipleDocumentsClick: () => void;
  onReadMoreOnLineItemsClick: () => void;
  onSaveWorkspace: (input: OnSaveWorkspaceInput) => Promise<boolean>;
  isLoading: boolean;
}

export function useWorkspaceSettingSection(args: WorkspaceSettingSectionProps) {
  const {
    workspace,
    onSaveWorkspace,
    onReadMoreOnLineItemsClick: onDocumentExportFlattenOptionReadMoreClick,
    isLoading,
  } = args;
  const { localized } = useLocale();

  const [isDirty, setIsDirty] = React.useState(false);
  const [workspaceName, setWorkspaceName] = React.useState(
    workspace.config.name
  );
  const [workspaceNameErrorMessageId, setWorkspaceNameErrorMessageId] =
    React.useState<string>();
  const [
    selectedDocumentExportFlattenOption,
    setSelectedDocumentExportFlattenOption,
  ] = React.useState<DocumentExportFlattenOption>(
    workspace.config.documentExportFlattenOption
  );

  const isSaveDisabled = React.useMemo(
    () => !isDirty || isLoading || workspaceNameErrorMessageId != null,
    [isDirty, isLoading, workspaceNameErrorMessageId]
  );

  const documentExportFlattenOptions = React.useMemo<
    { key: DocumentExportFlattenOption; text: string }[]
  >(() => {
    return [
      {
        key: DocumentExportFlattenOption.OneRowPerLineItem,
        text: localized(
          "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option.option.one_row_per_line_item"
        ),
      },
      {
        key: DocumentExportFlattenOption.OneFlattenedColumnPerLineItem,
        text: localized(
          "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option.option.one_flattened_column_per_line_item"
        ),
      },
    ];
  }, [localized]);

  const validateWorkspaceName = React.useCallback(
    (input?: string): null | { data: string } => {
      const data = (input ?? workspaceName).trim();
      if (data.length === 0) {
        setWorkspaceNameErrorMessageId("common.error.field_required");
        return null;
      }
      setWorkspaceNameErrorMessageId(undefined);
      return { data };
    },
    [workspaceName]
  );

  const validateInput = React.useCallback((): null | OnSaveWorkspaceInput => {
    const validWorkspaceName = validateWorkspaceName();
    if (validWorkspaceName == null) {
      return null;
    }
    return {
      name: validWorkspaceName.data,
      documentExportFlattenOption: selectedDocumentExportFlattenOption,
    };
  }, [selectedDocumentExportFlattenOption, validateWorkspaceName]);

  const onWorkspaceNameChange = React.useCallback(
    (
      _event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      const input = value ?? "";
      setIsDirty(true);
      setWorkspaceName(input);
      validateWorkspaceName(input);
    },
    [validateWorkspaceName]
  );

  const onSelectedDocumentExportFlattenOptionChange = React.useCallback(
    (
      _event?: React.SyntheticEvent<HTMLElement>,
      option?: IChoiceGroupOption
    ) => {
      setIsDirty(true);
      option &&
        setSelectedDocumentExportFlattenOption(
          option.key as DocumentExportFlattenOption
        );
    },
    []
  );

  const onConnectedExtractorSettingsClick = React.useCallback(() => {
    args.onConnectedExtractorClick(workspace, true);
  }, [args, workspace]);

  const onSaveWorkspaceClick = React.useCallback(async () => {
    const input = validateInput();
    if (input == null) {
      return;
    }
    const flag = await onSaveWorkspace(input);
    if (flag) {
      setIsDirty(false);
    }
  }, [onSaveWorkspace, validateInput]);

  return React.useMemo(
    () => ({
      workspace,
      documentExportFlattenOptions,
      workspaceName,
      workspaceNameErrorMessageId,
      onWorkspaceNameChange,
      selectedDocumentExportFlattenOption,
      onSelectedDocumentExportFlattenOptionChange,
      onDocumentExportFlattenOptionReadMoreClick,
      onConnectedExtractorSettingsClick,
      onSaveWorkspaceClick,
      isSaveDisabled,
      isDirty,
    }),
    [
      documentExportFlattenOptions,
      isSaveDisabled,
      onConnectedExtractorSettingsClick,
      onDocumentExportFlattenOptionReadMoreClick,
      onSaveWorkspaceClick,
      onSelectedDocumentExportFlattenOptionChange,
      onWorkspaceNameChange,
      selectedDocumentExportFlattenOption,
      workspace,
      workspaceName,
      workspaceNameErrorMessageId,
      isDirty,
    ]
  );
}

export function WorkspaceSettingSectionImpl(
  props: ReturnType<typeof useWorkspaceSettingSection>
) {
  const { localized } = useLocale();
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["section"]}>
          <div className={styles["section-header"]}>
            <div className={styles["section-header-title"]}>
              <FormattedMessage id="workspace.setting.section.workspace_settings.title" />
            </div>
          </div>
          <div className={styles["section-field"]}>
            <Label>
              <FormattedMessage id="workspace.setting.section.workspace_settings.field.workspace_name" />
            </Label>
            <TextField
              value={props.workspaceName}
              onChange={props.onWorkspaceNameChange}
              errorMessage={
                props.workspaceNameErrorMessageId != null
                  ? localized(props.workspaceNameErrorMessageId)
                  : undefined
              }
            />
          </div>
          <div className={styles["section-field"]}>
            <Label>
              <FormattedMessage id="workspace.setting.section.workspace_settings.field.workspace_id" />
            </Label>
            <CopyBlock content={props.workspace.id} />
          </div>
        </div>
        <div className={styles["section"]}>
          <div className={styles["section-header"]}>
            <div className={styles["section-header-title"]}>
              <FormattedMessage id="workspace.setting.section.document_processing_option.title" />
            </div>
          </div>
          <div className={styles["section-header"]}>
            {props.workspace.extractor?.processingMode && (
              <>
                <div className={styles["section-header-paragraph"]}>
                  <FormattedMessage
                    id={`workspace.setting.section.document_processing_option.${props.workspace.extractor?.processingMode}`}
                  />
                </div>
                <br />
              </>
            )}
            <div className={styles["section-header-paragraph"]}>
              <FormattedMessage
                id="workspace.setting.section.document_processing_option.change_settings"
                values={{
                  url: (
                    <span
                      className={styles["section-header-paragraph-button"]}
                      onClick={props.onConnectedExtractorSettingsClick}
                    >
                      <FormattedMessage id="workspace.setting.section.document_processing_option.extractor_settings" />
                    </span>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles["section"]}>
          <div className={styles["section-header"]}>
            <div className={styles["section-header-title"]}>
              <FormattedMessage id="workspace.setting.section.csv_exporting_option.title" />
            </div>
          </div>
          <div className={styles["section-field"]}>
            <div className={styles["section-field-question"]}>
              <Label className={styles["section-field-question-label"]}>
                {localized(
                  "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option"
                )}
              </Label>
              <ReadMoreButton
                className={styles["section-field-question-read-more"]}
                onClick={props.onDocumentExportFlattenOptionReadMoreClick}
              />
            </div>
            <ChoiceGroup
              options={props.documentExportFlattenOptions}
              selectedKey={props.selectedDocumentExportFlattenOption}
              onChange={props.onSelectedDocumentExportFlattenOptionChange}
            />
          </div>
        </div>
        <div className={styles["section"]}>
          <div className={styles["section-header"]}>
            <div className={styles["section-header-title"]}>
              <FormattedMessage id="workspace.setting.section.contact_us.title" />
            </div>
            <div className={styles["section-header-paragraph"]}>
              <FormattedMessage
                id="workspace.setting.section.contact_us.paragraph"
                values={{
                  url: (
                    <Link href="https://www.formx.ai/talk-with-us">
                      https://www.formx.ai/talk-with-us
                    </Link>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-[30px] px-5">
        <div className="h-[1px] bg-[#EDEBE9] mb-[11px]" />
        <div className="flex justify-end">
          <DangerButton
            textId="common.save"
            onClick={props.onSaveWorkspaceClick}
            disabled={props.isSaveDisabled}
          />
        </div>
      </div>
      <DataNotSavedPrompt
        isDataChanged={props.isDirty}
        titleTextId="workspace.setting.form_not_saved_prompt.title"
        messageTextId="workspace.setting.form_not_saved_prompt.save_warning"
        backTextId="workspace.setting.form_not_saved_prompt.go_back"
        continueTextId="workspace.setting.form_not_saved_prompt.leave_page"
      />
    </div>
  );
}

export function WorkspaceSettingSection(args: WorkspaceSettingSectionProps) {
  const props = useWorkspaceSettingSection(args);
  return <WorkspaceSettingSectionImpl {...props} />;
}
