import {
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  SearchBox,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import { escapeRegExp } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";

interface SearchableDropdownProps
  extends Omit<
    IDropdownProps,
    | "defaultSelectedKey"
    | "selectedKey"
    | "defaultSelectedKeys"
    | "selectedKeys"
  > {
  isLoadingOptions?: boolean;
  onSearchValueChange?: (value: string) => void;
  searchValue?: string;
  searchPlaceholder?: string;
  selectedItem?: IDropdownOption | null;
  optionsEmptyMessage?: React.ReactNode;
  onClear?: () => void;
}

function SearchableDropdownSearchBox(props: {
  onValueChange: ((value: string) => void) | undefined;
  value: string | undefined;
  placeholder: string | undefined;
}) {
  const { localized } = useLocale();
  const { value, onValueChange, placeholder } = props;

  const onChange = useCallback(
    (e?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e == null) {
        return;
      }
      const value = e.currentTarget.value;
      onValueChange?.(value);
    },
    [onValueChange]
  );

  const onClear = useCallback(() => {
    onValueChange?.("");
  }, [onValueChange]);

  return (
    <SearchBox
      className="border-[#E7E7E9]"
      placeholder={
        placeholder ?? localized("searchable_dropdown.search.placeholder")
      }
      underlined={true}
      showIcon={true}
      value={value}
      onChange={onChange}
      onClear={onClear}
      styles={{
        icon: {
          color: "#605E5C",
        },
      }}
    />
  );
}

function EmptyView(props: { message?: React.ReactNode }) {
  const { localized } = useLocale();
  const { message } = props;

  return (
    <Text block={true} className="text-[#BFBFC3] text-center py-2">
      {message ?? localized("searchable_dropdown.empty")}
    </Text>
  );
}

const EMPTY_CALLOUT_PROPS: IDropdownProps["calloutProps"] = {};

interface useSearchableDropdownProps {
  options: IDropdownOption[];
  isLoading?: boolean;
  selectedKey?: string;
  onSelected?: (key: number | string) => void;
}

export function useSearchableDropdownState(props: useSearchableDropdownProps) {
  const { options, onSelected, selectedKey } = props;
  const [searchValue, setSearchValue] = useState<string>();
  const selectedValue = useMemo<IDropdownOption | undefined>(
    () => options.find(o => o.key === selectedKey),
    [options, selectedKey]
  );

  const sanitizedSearchValue = useMemo(() => {
    if (searchValue) {
      return escapeRegExp(searchValue.toLowerCase());
    }
    return searchValue;
  }, [searchValue]);

  const filteredOptions = useMemo(() => {
    if (sanitizedSearchValue) {
      return options.map(o =>
        o.text.toLowerCase().search(sanitizedSearchValue) !== -1
          ? o
          : {
              ...o,
              hidden: true,
            }
      );
    }
    return options;
  }, [options, sanitizedSearchValue]);

  const onSearchValueChange = useCallback((keyword: string) => {
    setSearchValue(keyword);
  }, []);

  const onChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key) {
        onSelected?.(option.key);
      }
      setSearchValue(undefined);
    },
    [onSelected]
  );

  return React.useMemo(() => {
    return {
      options: filteredOptions,
      searchValue,
      onSearchValueChange,
      onChange,
      selectedItem: selectedValue,
    };
  }, [
    searchValue,
    onSearchValueChange,
    filteredOptions,
    onChange,
    selectedValue,
  ]);
}

const SearchableDropdownImpl = React.memo((props: SearchableDropdownProps) => {
  const {
    className,
    options,
    isLoadingOptions,
    onSearchValueChange,
    searchValue,
    searchPlaceholder,
    calloutProps = EMPTY_CALLOUT_PROPS,
    selectedItem,
    optionsEmptyMessage,
    onClear,
    placeholder,
    styles,
    ...restProps
  } = props;

  const { localized } = useLocale();

  const onRenderList = useCallback<NonNullable<IDropdownProps["onRenderList"]>>(
    (props?, defaultRenderer?) => {
      if (defaultRenderer == null) {
        return null;
      }

      const isOptionsEmpty = options.filter(o => !o.hidden).length === 0;

      return (
        <>
          <div className="pt-2 pb-1 sticky top-0 bg-white z-10">
            <SearchableDropdownSearchBox
              onValueChange={onSearchValueChange}
              value={searchValue}
              placeholder={searchPlaceholder}
            />
          </div>
          {isLoadingOptions ? (
            <div className="p-4 flex items-center justify-center">
              <Spinner size={SpinnerSize.xSmall} />
            </div>
          ) : isOptionsEmpty ? (
            <EmptyView message={optionsEmptyMessage} />
          ) : (
            defaultRenderer(props)
          )}
        </>
      );
    },
    [
      isLoadingOptions,
      onSearchValueChange,
      optionsEmptyMessage,
      searchPlaceholder,
      searchValue,
      options,
    ]
  );

  const combinedOptions = useMemo(() => {
    const providedOptionKeys = new Set(options.map(o => o.key));

    // Include all selected items as hidden options, if they are not
    // in `options`. This is needed for the dropdown to display
    // selected options correctly.
    const hiddenOptions: IDropdownOption[] = [];
    if (selectedItem && !providedOptionKeys.has(selectedItem.key)) {
      hiddenOptions.push({ ...selectedItem, hidden: true });
    }
    return options.concat(hiddenOptions);
  }, [options, selectedItem]);

  const selectedKey = useMemo(() => {
    if (selectedItem === null) {
      return null;
    }
    return selectedItem?.key;
  }, [selectedItem]);

  return (
    <Dropdown
      options={combinedOptions}
      onRenderList={onRenderList}
      {...restProps}
      className={className}
      calloutProps={{
        calloutMaxHeight: 264,
        calloutMinWidth: 200,
        alignTargetEdge: true,
        ...calloutProps,
      }}
      styles={{
        title: {
          borderWidth: 1,
          borderColor: "#8A8886",
        },
        caretDown: {
          color: "#605E5C",
        },
        ...styles,
      }}
      selectedKey={selectedKey}
      placeholder={placeholder ?? localized("searchable_dropdown.placeholder")}
    />
  );
});

const SearchableDropdown = React.memo((props: useSearchableDropdownProps) => {
  const state = useSearchableDropdownState(props);

  return <SearchableDropdownImpl {...state} />;
});

export default SearchableDropdown;
