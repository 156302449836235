import { isDate } from "lodash";
import { DateTime } from "luxon";

export const dateFormatPattern = "MMM dd, yyyy";

export function formatDate(
  dateISO: string | DateTime | Date,
  pattern: string
): string {
  let date: DateTime;
  if (typeof dateISO === "string") {
    date = DateTime.fromISO(dateISO);
  } else if (isDate(dateISO)) {
    date = DateTime.fromJSDate(dateISO);
  } else {
    date = dateISO;
  }
  return date.toFormat(pattern);
}

export function parseISO(dateISO: string): DateTime {
  return DateTime.fromISO(dateISO);
}

export function isToday(datetime: DateTime) {
  return (
    DateTime.now().toFormat(dateFormatPattern) ===
    datetime.toFormat(dateFormatPattern)
  );
}

export function isYesterday(datetime: DateTime) {
  return (
    DateTime.now().minus({ day: 1 }).toFormat(dateFormatPattern) ===
    datetime.toFormat(dateFormatPattern)
  );
}

export function startOfToday() {
  return DateTime.now().startOf("day");
}
