import { Text, TooltipHost } from "@fluentui/react";
import cntl from "cntl";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";

interface Props {
  diffTable: string[][];
}

const SnapshotVersionDiffTable = React.memo((props: Props) => {
  const { diffTable } = props;

  const { localized } = useLocale();

  const schemeRowTags = React.useMemo(() => {
    return diffTable[0][0] === "!" ? diffTable[0] : [];
  }, [diffTable]);

  const localizedSchemeRowTagTooltip = useCallback(
    (symbol: string) => {
      if (symbol === "+++") {
        return localized("snapshot_version_diff_table.tooltip.added_col");
      }
      if (symbol === "---") {
        return localized("snapshot_version_diff_table.tooltip.deleted_col");
      }
      if (symbol === ":") {
        return localized("snapshot_version_diff_table.tooltip.reordered_col");
      }
      return;
    },
    [localized]
  );

  const header = React.useMemo(() => {
    const hv = diffTable.find(d => d[0] === "@@");
    return (
      <>
        {schemeRowTags.length > 0 && (
          <tr>
            {schemeRowTags.map((c, i) => {
              const localizedSymbol = localizedSchemeRowTagTooltip(c);
              return (
                <td
                  className={
                    "bg-[#aaa] pb-1 pt-1.5 px-1 text-left border-[#2D4068] border-solid border"
                  }
                  key={i}
                >
                  {localizedSymbol ? (
                    <TooltipHost
                      content={
                        <Text className="text-xs font-normal text-[#201F1E] p-2">
                          {localizedSchemeRowTagTooltip(c)}
                        </Text>
                      }
                      calloutProps={{
                        isBeakVisible: true,
                      }}
                    >
                      {c}
                    </TooltipHost>
                  ) : (
                    c
                  )}
                </td>
              );
            })}
          </tr>
        )}
        <tr>
          {hv?.map((h, i) => (
            <th
              className={cntl`min-w-12 bg-[#aaf] font-bold pb-1 pt-1.5 px-1 text-left border-[#2D4068] border-solid border border-b-2`}
              key={i}
            >
              {h}
            </th>
          )) ?? []}
        </tr>
      </>
    );
  }, [diffTable, schemeRowTags, localizedSchemeRowTagTooltip]);

  const localizedActionColumnTagTooltip = useCallback(
    (symbol: string) => {
      if (symbol === "+++") {
        return localized("snapshot_version_diff_table.tooltip.added_row");
      }
      if (symbol === "---") {
        return localized("snapshot_version_diff_table.tooltip.deleted_row");
      }
      if (symbol.search("->") !== -1) {
        return localized("snapshot_version_diff_table.tooltip.updated_row");
      }
      if (symbol === ":") {
        return localized("snapshot_version_diff_table.tooltip.reordered_row");
      }
      return;
    },
    [localized]
  );

  const rows = React.useMemo(() => {
    const headerIndex = diffTable.findIndex(d => d[0] === "@@");
    const _rows = [];

    for (let i = headerIndex + 1; i < diffTable.length; i++) {
      const actionSymbol = diffTable[i][0];
      const isUpdatedAction = actionSymbol.search("->") !== -1;
      _rows.push(
        <tr key={i}>
          {diffTable[i].map((c, j) => {
            const tooltip = j === 0 && localizedActionColumnTagTooltip(c);
            return (
              <td
                className={cntl`border-[#2D4068] border-solid border pt-1.5 pb-2 px-2 ${
                  actionSymbol === "+++"
                    ? "bg-[#7fff7f]"
                    : actionSymbol === "---"
                    ? "bg-[#ff7f7f]"
                    : actionSymbol === ":"
                    ? "bg-[#ffa]"
                    : isUpdatedAction && c.search(actionSymbol) !== -1
                    ? "bg-[#7f7fff]"
                    : schemeRowTags[j] === "+++"
                    ? "bg-[#7fff7f]"
                    : ""
                }`}
                key={j}
              >
                {tooltip ? (
                  <TooltipHost
                    content={
                      <Text className="text-xs font-normal text-[#201F1E] p-2">
                        {tooltip}
                      </Text>
                    }
                    calloutProps={{
                      isBeakVisible: true,
                    }}
                  >
                    {c}
                  </TooltipHost>
                ) : (
                  c
                )}
              </td>
            );
          })}
        </tr>
      );
    }
    return _rows;
  }, [diffTable, localizedActionColumnTagTooltip, schemeRowTags]);

  if (rows.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <p>{localized("snapshot_version_diff_table.label.empty")}</p>
      </div>
    );
  }

  return (
    <div>
      <table className="border-collapse">
        <thead className="border-black border-solid border">{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
});

export default SnapshotVersionDiffTable;
