import daff from "daff";
import ExcelJS, { Workbook } from "exceljs";

import { xlsxColors } from "../constants/tableDiff";

function diffTableView(tableA: string[][], tableB: string[][]): daff.TableView {
  const ta = new daff.TableView(tableA);
  const tb = new daff.TableView(tableB);

  const comp = daff.compareTables(ta, tb);
  const alignment = comp.align();

  const t_diff = new daff.TableView([]);

  const flags = new daff.CompareFlags();
  const highlighter = new daff.TableDiff(alignment, flags);
  highlighter.hilite(t_diff);
  return t_diff;
}

export function tableDiff(tableA: string[][], tableB: string[][]): string[][] {
  return diffTableView(tableA, tableB).data;
}

export function tableDiffAsHtml(
  tableA: string[][],
  tableB: string[][]
): string {
  const diff = diffTableView(tableA, tableB);
  const diff2html = new daff.DiffRender();
  diff2html.render(diff);
  diff2html.completeHtml();
  return diff2html.html();
}

export interface TableDiffDescription {
  readonly added: number;
  readonly removed: number;
  readonly updated: number;
}

export const emptyTableDiffDescription: TableDiffDescription = {
  added: 0,
  removed: 0,
  updated: 0,
};

export function tableDiffAsDescription(
  tableA: string[][],
  tableB: string[][]
): TableDiffDescription {
  const tf = tableDiff(tableA, tableB);
  let added = 0;
  let removed = 0;
  let updated = 0;
  if (tf) {
    for (const r of tf) {
      const c = r[0];
      if (c === "+++") {
        added++;
      } else if (c === "---") {
        removed++;
      } else if (c !== "" && c !== "!" && c !== "@@" && c !== "...") {
        updated++;
      }
    }
  }
  return {
    added,
    removed,
    updated,
  };
}

function rangeApply(
  sheet: ExcelJS.Worksheet,
  leftTop: number[],
  rightBottom: number[],
  fill: ExcelJS.Fill
) {
  const [x, y] = leftTop;
  const [x1, y1] = rightBottom;

  for (let i = x; i <= x1; i++) {
    for (let j = y; j <= y1; j++) {
      sheet.getCell(i, j).fill = fill;
    }
  }
}

export function tableDiffAsExcel(
  tableA: string[][],
  tableB: string[][],
  dst?: {
    workbook: Workbook;
    sheetName: string;
  }
): Workbook {
  const diff = diffTableView(tableA, tableB).data;
  const workbook = dst?.workbook ?? new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet(dst?.sheetName ?? "sheet1");

  sheet.addRows(diff);

  const schemeRow = diff[0][0] === "!" ? diff[0] : [];
  const rows = diff.length;
  const cols = diff[0].length;

  if (schemeRow.length > 0) {
    rangeApply(sheet, [1, 1], [1, cols], {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: xlsxColors.schemeRow },
    });
  }

  const headerIndex = diff.findIndex(r => r[0] === "@@");
  rangeApply(sheet, [headerIndex + 1, 1], [headerIndex + 1, cols], {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: xlsxColors.header },
  });

  for (let i = 0; i < schemeRow.length; i++) {
    let color: string | undefined;
    if (schemeRow[i] === "+++") {
      color = xlsxColors.addedRow;
    }
    if (schemeRow[i] === "---") {
      color = xlsxColors.removedRow;
    }
    if (schemeRow[i] === ":") {
      color = xlsxColors.reorderRow;
    }
    if (color) {
      rangeApply(sheet, [1, i + 1], [rows, i + 1], {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      });
    }
  }
  for (let i = 0; i < diff.length; i++) {
    let color: string | undefined;
    if (diff[i][0] === "+++") {
      color = xlsxColors.addedRow;
    }
    if (diff[i][0] === "---") {
      color = xlsxColors.removedRow;
    }
    if (diff[i][0] === ":") {
      color = xlsxColors.reorderRow;
    }
    if (color) {
      rangeApply(sheet, [i + 1, 1], [i + 1, cols], {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      });
    }
    if (diff[i][0].search("->") !== -1) {
      color = xlsxColors.modified;
      const actionSymbol = diff[i][0];
      for (let j = 0; j < diff[i].length; j++) {
        if (diff[i][j].search(actionSymbol) !== -1) {
          sheet.getCell(i + 1, j + 1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: color },
          };
        }
      }
    }
  }
  return workbook;
}
