import * as yup from "yup";

import { LLMModel } from "../types/llmModel";
import { AdvancedPatternMatching } from "./advancedPatternMatching";
import { DocumentType } from "./form";
import { ImageQualityConfig } from "./imageQuality";
import { LLMCompletion } from "./llmCompletion";
import { FormatterStorage } from "./mappers/formatter";
import {
  MerchantExtractionFallbackStrategry,
  MerchantSettingResp,
} from "./merchantSetting";
import {
  Abbyy,
  AzureComputerVision,
  GoogleCloudVision,
  Tesseract,
} from "./ocrConfig";
import { ProcessingMode } from "./processingMode";

const DateInputFormatTypeSchema = yup
  .string()
  .oneOf(["DMY", "MDY", "YMD"])
  .defined();

export type DateInputFormatType = yup.InferType<
  typeof DateInputFormatTypeSchema
>;

export type FormSettings = {
  name: string;
  numFormFeature: number;
  numQueryFeature: number;
  documentType: DocumentType;
  autoExtractionItems: AutoExtractionItem[];
  postProcessScript?: string;
  transformResponseScript?: string;
  dateFormat?: string;
  dateInputFormat?: DateInputFormatType;
  merchantNames?: string[];
  formatter?: FormatterStorage;
  llmCompletions?: { [key: string]: LLMCompletion };
  llmModelInUse?: LLMModel;
};

export type OCRConfigEngine =
  | typeof Tesseract
  | typeof GoogleCloudVision
  | typeof AzureComputerVision
  | typeof Abbyy;

export const ocrConfigSchema = yup.object({
  engine: yup.mixed((_input): _input is OCRConfigEngine => true).optional(),
  api: yup.string().optional(),
  languages: yup.array(yup.string().defined()).optional(),
});

export type OCRConfig = yup.InferType<typeof ocrConfigSchema>;

export type FormConfig = {
  detector: DetectorConfig;
  index_param: IndexParam;
  search_param: SearchParam;
  document_type: DocumentType;
  auto_extraction_items: AutoExtractionItem[];
  post_process_script?: string;
  transform_response_script?: string;
  custom_merchants?: MerchantSettingResp[];
  custom_merchants_fallback?: MerchantExtractionFallbackStrategry;
  date_format?: string;
  date_input_format?: DateInputFormatType;
  merchant_names?: string[];
  prebuilt_extractor?: string;
  formatter?: FormatterStorage;
  llm_completions?: { [key: string]: LLMCompletion };
  advanced_pattern_matching?: AdvancedPatternMatching;
  ocr_config?: OCRConfig;
  processing_mode?: `${ProcessingMode}`;
  llm_model_in_use?: string;
  image_quality_config?: ImageQualityConfig;
};

export type ORBConfig = {
  type: "orb";
  number_of_source_feature: number;
  number_of_query_feature: number;
};

export type DetectorConfig = ORBConfig;

export type IndexParam = {
  algorithm: number;
  table_number: number;
  key_size: number;
  multi_probe_level: number;
};

export type SearchParam = {
  checks: number;
};

export enum Language {
  English = "eng",
  TraditionalChinese = "chi-tra",
}

export type AutoExtractionItem =
  | "total_amount"
  | "date"
  | "short_date"
  | "time"
  | "name"
  | "address"
  | "invoice_number"
  | "telephone"
  | "merchant_fax"
  | "merchant_website"
  | "merchant_name"
  | "store_number"
  | "subtotal"
  | "rounding"
  | "total_discount"
  | "enter_time"
  | "leave_time"
  | "num_person"
  | "credit_card_type"
  | "credit_card_no"
  | "payment_name"
  | "payment_card_no"
  | "product_info"
  | "payment_info"
  | "company"
  | "job_title"
  | "merchant"
  | "detect_manipulation"
  | "detect_document_manipulation"
  | "issue_date"
  | "table_extraction"
  | "ner_name"
  | "ner_address"
  | "account_no"
  | "bank_account_no"
  | "swift_bic"
  | "bank_name"
  | "total"
  | "payment_terms"
  | "currency"
  | "discount"
  | "subtotal"
  | "tax"
  | "invoice_date"
  | "order_date"
  | "delivery_date"
  | "due_date"
  | "end_date"
  | "start_date"
  | "invoice_number"
  | "purchase_order_no"
  | "reference_no"
  | "package_weight"
  | "bill_to_address"
  | "bill_to_email"
  | "bill_to_name"
  | "bill_to_phone"
  | "bill_to_vat_number"
  | "ship_to_address"
  | "ship_to_name"
  | "vendor_address"
  | "vendor_email"
  | "vendor_fax"
  | "vendor_name"
  | "vendor_phone"
  | "vendor_vat_number"
  | "vendor_website"
  | "address_code"
  | "address_full"
  | "code_document"
  | "code_identity"
  | "date_birth"
  | "date_expiry"
  | "date_issue"
  | "gender"
  | "issuing_authority"
  | "name_full"
  | "name_full_non_latin"
  | "nationality"
  | "place_of_birth"
  | "detect_signature"
  | "barcode"
  | "detect_portrait"
  | "pii"
  | "passport_mrz"
  | "llm_completion"
  | "vat"
  | "bank_info"
  | "vendor_name"
  | "vendor_address"
  | "vendor_email"
  | "vendor_telephone"
  | "vendor_vat_number"
  | "vendor_url"
  | "vendor_fax"
  | "customer_name"
  | "customer_address"
  | "customer_email"
  | "customer_telephone"
  | "customer_vat_number"
  | "shipment_name"
  | "shipment_address"
  | "shipment_email"
  | "shipment_telephone"
  | "shipment_vat_number"
  | "line_items"
  | "account_type"
  | "account_info"
  | "open_balance"
  | "close_balance"
  | "transactions"
  | "ship_date"
  | "bill_of_lading_no"
  | "shipper_name"
  | "shipper_address"
  | "shipper_city"
  | "shipper_state"
  | "shipper_zip"
  | "shipper_phone"
  | "shipper_email"
  | "shipper_reference"
  | "carrier_name"
  | "carrier_address"
  | "carrier_reference"
  | "carrier_fedex_express_air_waybill_no"
  | "consignee_name"
  | "consignee_address"
  | "consignee_city"
  | "consignee_state"
  | "consignee_zip"
  | "consignee_phone"
  | "consignee_email"
  | "port_of_loading"
  | "port_of_unloading"
  | "num_of_packages"
  | "freight_weight"
  | "freight_length"
  | "freight_width"
  | "freight_height"
  | "freight_condition"
  | "freight_description"
  | "freight_identification_marks"
  | "vessel_name"
  | "vessel_id"
  | "terms_of_contract"
  | "receipt_type"
  | "mobile_payment_transaction_number"
  | "auth_code"
  | "retrieval_reference_number"
  | "payment_info_beta"
  | "merchant_logo"
  | "id_liveness";
