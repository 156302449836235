import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import sampleImage from "../../images/sample/workspace-sample-image.svg";
import { WorkspaceCsvOutputSampleTable } from "../WorkspaceCsvOutputSampleTable";
import styles from "./styles.module.scss";

interface WorkspaceSettingLineItemsTutorialProps {
  onCloseClick?: () => void;
}

export function useWorkspaceSettingLineItemsTutorial(
  args: WorkspaceSettingLineItemsTutorialProps
) {
  const { onCloseClick } = args;

  const documentExportFlattenOptionCsvOutputSampleColumns = React.useMemo(
    () => [
      { key: "fileName" as const, header: "file_name" },
      { key: "lineItem0Name" as const, header: "line_item/0/name" },
      { key: "lineItem0Price" as const, header: "line_item/0/price" },
      { key: "lineItem1Name" as const, header: "line_item/1/name" },
      { key: "lineItem1Price" as const, header: "line_item/1/price" },
    ],
    []
  );

  const documentExportFlattenOptionCsvOutputSampleItems = React.useMemo(
    () => [
      {
        key: "0",
        fileName: "sample.pdf",
        lineItem0Name: "Design consultation",
        lineItem0Price: "123",
        lineItem1Name: "App development",
        lineItem1Price: "127",
      },
    ],
    []
  );

  const documentFileOptionCsvOutputSampleColumns = React.useMemo(
    () => [
      { key: "fileName" as const, header: "file_name" },
      { key: "lineItems" as const, header: "line_items" },
      { key: "name" as const, header: "name" },
      { key: "price" as const, header: "price" },
    ],
    []
  );

  const documentFileOptionCsvOutputSampleItems = React.useMemo(
    () => [
      {
        key: "0",
        fileName: "sample.pdf",
        lineItems: "line_item/0",
        name: "Design consultation",
        price: "123",
      },
      {
        key: "1",
        fileName: "sample.pdf",
        lineItems: "line_item/1",
        name: "App development",
        price: "127",
      },
    ],
    []
  );

  return React.useMemo(
    () => ({
      onCloseClick,
      documentExportFlattenOptionCsvOutputSampleColumns,
      documentExportFlattenOptionCsvOutputSampleItems,
      documentFileOptionCsvOutputSampleColumns,
      documentFileOptionCsvOutputSampleItems,
    }),
    [
      documentExportFlattenOptionCsvOutputSampleColumns,
      documentExportFlattenOptionCsvOutputSampleItems,
      documentFileOptionCsvOutputSampleColumns,
      documentFileOptionCsvOutputSampleItems,
      onCloseClick,
    ]
  );
}

export function WorkspaceSettingLineItemsTutorialImpl(
  props: ReturnType<typeof useWorkspaceSettingLineItemsTutorial>
) {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <div className={styles["title"]}>
            <FormattedMessage id="workspace.setting.tutorial.line_items.title" />
          </div>
          <div className={styles["close-button"]} onClick={props.onCloseClick}>
            <Icon className={styles["icon"]} iconName="Cancel" />
          </div>
        </div>
        <div className={styles["sections"]}>
          <div className={styles["section"]}>
            <div className={styles["info"]}>
              <div className={styles["title"]}>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.what_are_line_items.title" />
              </div>
              <div className={styles["description"]}>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.what_are_line_items.description" />
              </div>
            </div>
            <div className={styles["samples"]}>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.sample_image" />
                </div>
                <div className={styles["image"]}>
                  <img src={sampleImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["section"]}>
            <div className={styles["info"]}>
              <div className={styles["title"]}>
                <div className={styles["option"]}>
                  <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_1.option" />
                </div>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_1.title" />
              </div>
              <div className={styles["description"]}>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_1.description" />
              </div>
            </div>
            <div className={styles["samples"]}>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.csv_output_sample" />
                </div>
                <div className={styles["table"]}>
                  <WorkspaceCsvOutputSampleTable
                    columns={props.documentFileOptionCsvOutputSampleColumns}
                    items={props.documentFileOptionCsvOutputSampleItems}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["section"]}>
            <div className={styles["info"]}>
              <div className={styles["title"]}>
                <div className={styles["option"]}>
                  <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_2.option" />
                </div>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_2.title" />
              </div>
              <div className={styles["description"]}>
                <FormattedMessage id="workspace.setting.tutorial.line_items.section.csv_export_option_2.description" />
              </div>
            </div>
            <div className={styles["samples"]}>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.csv_output_sample" />
                </div>
                <div className={styles["table"]}>
                  <WorkspaceCsvOutputSampleTable
                    columns={
                      props.documentExportFlattenOptionCsvOutputSampleColumns
                    }
                    items={
                      props.documentExportFlattenOptionCsvOutputSampleItems
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function WorkspaceSettingLineItemsTutorial(
  args: WorkspaceSettingLineItemsTutorialProps
) {
  const props = useWorkspaceSettingLineItemsTutorial(args);
  return <WorkspaceSettingLineItemsTutorialImpl {...props} />;
}
