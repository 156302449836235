import { format } from "@fast-csv/format";
import JSZip from "jszip";

import {
  CellData,
  CellDataType,
  KeyValueConfig,
  MenuItem,
  RowData,
  SettingCellData,
} from "../../advancedTokenSetup/table";

const fieldReplacementHeader = [
  "tag",
  "invoice_number_custom_model_field_name",
  "invoice_number_pattern",
  "invoice_number_position",
  "invoice_number_token",
  "invoice_number_token_is_fuzzy_search",
  "total_amount_custom_model_field_name",
  "total_amount_pattern",
  "total_amount_position",
  "total_amount_token",
  "total_amount_token_is_fuzzy_search",
];

export const baseCSVHeader: Record<MenuItem, string[]> = {
  TagMall: ["mall_id"],
  TagMerchant: ["merchant_id"],
  ExactMatchRule: [
    "merchant_id",
    "mall_id",
    "brand_name",
    "brand_name_is_remove_space",
    "brand_name_is_exact_match",
    "email",
    "email_is_remove_space",
    "email_is_exact_match",
    "phone_number",
    "phone_number_is_remove_space",
    "phone_number_is_exact_match",
    "fax_number",
    "fax_number_is_remove_space",
    "fax_number_is_exact_match",
    "url",
    "url_is_remove_space",
    "url_is_exact_match",
  ],
  FieldReplacementMerchant: fieldReplacementHeader,
  FieldReplacementMall: fieldReplacementHeader,
  ClosestMatchRuleMerchant: [
    "merchant_id",
    "token",
    "token_is_remove_space",
    "token_is_exact_match",
  ],
  ClosestMatchRuleMall: [
    "mall_id",
    "token",
    "token_is_remove_space",
    "token_is_exact_match",
  ],
};

const positiveTokenHeader = [
  "positive_token",
  "positive_token_is_remove_space",
  "positive_token_is_exact_match",
  "positive_token_is_regex",
];

const negativeTokenHeader = [
  "negative_token",
  "negative_token_is_remove_space",
  "negative_token_is_exact_match",
  "negative_token_is_regex",
];

export const defaultCSVHeader: Record<MenuItem, string[]> = {
  TagMall: baseCSVHeader.TagMall,
  TagMerchant: baseCSVHeader.TagMerchant,
  ExactMatchRule: [
    ...baseCSVHeader.ExactMatchRule,
    ...new Array(2).fill(positiveTokenHeader).flat(),
    ...new Array(2).fill(negativeTokenHeader).flat(),
  ],
  FieldReplacementMall: baseCSVHeader.FieldReplacementMall,
  FieldReplacementMerchant: baseCSVHeader.FieldReplacementMerchant,
  ClosestMatchRuleMall: baseCSVHeader.ClosestMatchRuleMall,
  ClosestMatchRuleMerchant: baseCSVHeader.ClosestMatchRuleMerchant,
};

export const defaultCSVFilename: Record<MenuItem, string> = {
  TagMall: "tag_groups-mall_id.csv",
  TagMerchant: "tag_groups-merchant_id.csv",
  ExactMatchRule: "exact_match_rules.csv",
  FieldReplacementMall: "field_replacement-mall_id.csv",
  FieldReplacementMerchant: "field_replacement-merchant_id.csv",
  ClosestMatchRuleMall: "closest_match_rules-mall_id.csv",
  ClosestMatchRuleMerchant: "closest_match_rules-merchant_id.csv",
};

export const defaultCSVContent: Record<MenuItem, string[]> = {
  TagMall: ["put_mall_id_here"],
  TagMerchant: ["put_merchant_id_here"],
  ExactMatchRule: [
    "put_merchant_id_here",
    "put_mall_id_here",
    "put_brand_name_here",
    "FALSE",
    "FALSE",
    "put_email_here",
    "FALSE",
    "FALSE",
    "put_phone_number_here",
    "FALSE",
    "FALSE",
    "put_fax_number_here",
    "FALSE",
    "FALSE",
    "put_url_here",
    "FALSE",
    "FALSE",
    "put_positive_token_here",
    "FALSE",
    "FALSE",
    "FALSE",
    "put_positive_token_here",
    "FALSE",
    "FALSE",
    "FALSE",
    "put_negative_token_here",
    "FALSE",
    "FALSE",
    "FALSE",
    "put_negative_token_here",
    "FALSE",
    "FALSE",
    "FALSE",
  ],
  FieldReplacementMall: [],
  FieldReplacementMerchant: [],
  ClosestMatchRuleMall: [
    "put_mall_id_here",
    "put_text_token_here",
    "FALSE",
    "FALSE",
  ],
  ClosestMatchRuleMerchant: [
    "put_merchant_id_here",
    "put_text_token_here",
    "FALSE",
    "FALSE",
  ],
};

export const MerchantCSVWriter = {
  getDefaultTemplate(
    item: MenuItem
  ): [header: string, data: string, filename: string] {
    return [
      defaultCSVHeader[item].join(","),
      defaultCSVContent[item].join(","),
      defaultCSVFilename[item],
    ];
  },

  async generateZip(records: Record<MenuItem, string[][]>): Promise<Blob> {
    const files = await Promise.all(
      Object.keys(records).map(async menu => {
        const filename = defaultCSVFilename[menu as MenuItem];
        const csv = records[menu as MenuItem] as any[];

        const csvContent = (await new Promise((resolve, reject) => {
          const chunks = [] as Buffer[];
          const stream = format({ delimiter: "," });
          stream.on("data", chunk => chunks.push(Buffer.from(chunk)));
          stream.on("error", err => reject(err));
          stream.on("end", () =>
            resolve(Buffer.concat(chunks).toString("utf8"))
          );
          csv.forEach(row => stream.write(row));
          stream.end();
        })) as string;

        return {
          csv: csvContent,
          filename,
        };
      })
    );

    const zip = new JSZip();

    files.forEach(file => {
      zip.file(file.filename, file.csv);
    });
    return zip.generateAsync({ type: "blob" });
  },
};

function normalizeTrueFalse(value?: boolean) {
  return value ? "TRUE" : "FALSE";
}

export class MerchantPatternMatchingCSVWriter {
  // indicate whether to add serial number for repeated items with same header name
  distinctHeader: boolean;

  constructor(options?: { distinctHeader: boolean }) {
    this.distinctHeader = options?.distinctHeader ?? false;
  }

  processTagRows(menuItem: MenuItem, rowData: RowData[]) {
    const header =
      menuItem === MenuItem.TagMall
        ? defaultCSVHeader.TagMall
        : defaultCSVHeader.TagMerchant;

    const rows = rowData.map(row => {
      return row.data.map(cell => {
        if (cell.type === CellDataType.TagCellData) {
          return cell.tag;
        }
        return "";
      });
    });
    return [header].concat(rows);
  }

  processAutoCompleteCell(cell: CellData) {
    if (cell.type === CellDataType.TagAutoCompleteCellData) {
      return cell.tag;
    }
    return "";
  }

  processTokenCell(cell: CellData, hasRegExp: boolean = false) {
    if (cell.type === CellDataType.TokenCellData) {
      if (cell.token.pattern === "" || cell.token.pattern === undefined) {
        return ["", "", "", hasRegExp ? "" : undefined].filter(
          item => item !== undefined
        ) as string[];
      }

      return [
        cell.token.pattern,
        normalizeTrueFalse(cell.token.isClearSpace),
        normalizeTrueFalse(cell.token.isExactMatch),
        hasRegExp ? normalizeTrueFalse(cell.token.isRegex) : undefined,
      ].filter(item => item !== undefined) as string[];
    }
    return "";
  }

  processSettingCell(cell: CellData, maxTokensCount: number): string[] {
    if (cell.type === CellDataType.SettingCellData) {
      const emptyTokenFields = ["", ""];
      if (!cell.config) {
        if (cell.target === "date") {
          return new Array(6 + emptyTokenFields.length * maxTokensCount).fill(
            ""
          );
        }
        return new Array(4 + emptyTokenFields.length * maxTokensCount).fill("");
      }
      const result = [];
      if (cell.config.type === "key_value") {
        result.push(cell.config.config.pattern);
        result.push(cell.config.config.position);
        result.push(
          ...cell.config.config.tokens
            .map(t => [t.token, normalizeTrueFalse(t.use_fuzzy_search)])
            .flat()
        );
        result.push(
          ...new Array(maxTokensCount - cell.config.config.tokens.length)
            .fill(emptyTokenFields)
            .flat()
        );
      } else {
        result.push("", "");
        result.push(...new Array(maxTokensCount).fill(emptyTokenFields).flat());
      }
      if (cell.config.type === "custom_model") {
        result.push(cell.config.config.custom_model_id);
        result.push(cell.config.config.field_name);
        if (cell.target === "date") {
          result.push(cell.config.config.date_input_format ?? "");
        }
      } else {
        result.push("", "");
        if (cell.target === "date") {
          result.push("");
        }
      }
      if (cell.target === "date") {
        if (cell.config.type === "change_date_input") {
          result.push(cell.config.config.date_input_format);
        } else {
          result.push("");
        }
      }
      return result;
    }
    return [];
  }

  processExactMatchRuleRows(rowData: RowData[]) {
    const header = [...baseCSVHeader.ExactMatchRule];

    let positiveTokenCount = 2;
    let negativeTokenCount = 2;

    if (rowData.length > 0) {
      positiveTokenCount = rowData[0].data.filter(cell => {
        if (cell.type === CellDataType.TokenCellData) {
          return cell.token.type === "positive_token";
        }
        return false;
      }).length;

      negativeTokenCount = rowData[0].data.filter(cell => {
        if (cell.type === CellDataType.TokenCellData) {
          return cell.token.type === "negative_token";
        }
        return false;
      }).length;
    }

    header.push(
      ...new Array(positiveTokenCount)
        .fill(positiveTokenHeader)
        .map((p: string[], i) =>
          this.distinctHeader ? p.map(pp => `${pp}_${i + 1}`) : p
        )
        .flat(),
      ...new Array(negativeTokenCount)
        .fill(negativeTokenHeader)
        .map((p: string[], i) =>
          this.distinctHeader ? p.map(pp => `${pp}_${i + 1}`) : p
        )
        .flat()
    );

    const rows = rowData.map(row => {
      let index = 0;
      const res = [];
      res.push(this.processAutoCompleteCell(row.data[index++]));
      res.push(this.processAutoCompleteCell(row.data[index++]));
      res.push(this.processTokenCell(row.data[index++])); //brand_name
      res.push(this.processTokenCell(row.data[index++])); //email
      res.push(this.processTokenCell(row.data[index++])); //phone number
      res.push(this.processTokenCell(row.data[index++])); //fax number
      res.push(this.processTokenCell(row.data[index++])); //url

      while (index < row.data.length) {
        res.push(this.processTokenCell(row.data[index++], true)); //positive or negative token
      }
      return res.flat();
    });

    return [header].concat(rows);
  }

  processClosestMatchRuleRows(menuItem: MenuItem, rowData: RowData[]) {
    const header =
      menuItem === MenuItem.ClosestMatchRuleMall
        ? defaultCSVHeader.ClosestMatchRuleMall
        : defaultCSVHeader.ClosestMatchRuleMerchant;

    const rows = rowData.map(row => {
      let index = 0;
      const res = [];
      res.push(this.processAutoCompleteCell(row.data[index++]));
      res.push(this.processTokenCell(row.data[index++]));
      return res.flat();
    });
    return [header].concat(rows);
  }
  _getKeyValueTokenCount(rowData: RowData[], target: string) {
    return rowData
      .map(r =>
        r.data.filter(
          d =>
            d.type === CellDataType.SettingCellData &&
            d.target === target &&
            d.config &&
            d.config.type === "key_value"
        )
      )
      .flat()
      .map(
        kv =>
          ((kv as SettingCellData).config as KeyValueConfig).config.tokens
            .length
      )
      .reduce((max, cur) => {
        return Math.max(max, cur);
      }, 0);
  }
  processFieldReplacementRows(
    menuItem: MenuItem.FieldReplacementMerchant | MenuItem.FieldReplacementMall,
    rowData: RowData[]
  ) {
    const maxKeyValueCounts = {
      invoice_number: Math.max(
        1,
        this._getKeyValueTokenCount(rowData, "invoice_number")
      ),
      total_amount: Math.max(
        1,
        this._getKeyValueTokenCount(rowData, "total_amount")
      ),
      date: Math.max(1, this._getKeyValueTokenCount(rowData, "date")),
    };

    const headers: string[] = [
      menuItem === MenuItem.FieldReplacementMerchant
        ? "merchant_id"
        : "mall_id",
      "invoice_number_pattern",
      "invoice_number_position",
      ...new Array(maxKeyValueCounts.invoice_number)
        .fill("")
        .fill(["invoice_number_token", "invoice_number_token_is_fuzzy_search"])
        .map((fields: string[], i) =>
          this.distinctHeader ? fields.map(f => `${f}_${i}`) : fields
        )
        .flat(),
      "invoice_number_custom_model_id",
      "invoice_number_custom_model_field_name",
      "total_amount_pattern",
      "total_amount_position",
      ...new Array(maxKeyValueCounts.total_amount)
        .fill(["total_amount_token", "total_amount_token_is_fuzzy_search"])
        .map((fields: string[], i) =>
          this.distinctHeader ? fields.map(f => `${f}_${i}`) : fields
        )
        .flat(),
      "total_amount_custom_model_id",
      "total_amount_custom_model_field_name",
      "date_pattern",
      "date_position",
      ...new Array(maxKeyValueCounts.date)
        .fill([`date_token`, `date_token_is_fuzzy_search`])
        .map((fields: string[], i) =>
          this.distinctHeader ? fields.map(f => `${f}_${i}`) : fields
        )
        .flat(),
      "date_custom_model_id",
      "date_custom_model_field_name",
      "date_custom_model_change_date_input_format",
      "date_change_date_input_format",
    ];
    const rows = rowData.map(row => {
      let index = 0;
      return [
        this.processAutoCompleteCell(row.data[index++]),
        ...this.processSettingCell(
          row.data[index++],
          maxKeyValueCounts.invoice_number
        ),
        ...this.processSettingCell(
          row.data[index++],
          maxKeyValueCounts.total_amount
        ),
        ...this.processSettingCell(row.data[index++], maxKeyValueCounts.date),
      ];
    });
    return [headers].concat(rows);
  }

  processRows(tableType: MenuItem, rowData: RowData[]) {
    switch (tableType) {
      case MenuItem.TagMall:
        return this.processTagRows(tableType, rowData);
      case MenuItem.TagMerchant:
        return this.processTagRows(tableType, rowData);
      case MenuItem.ExactMatchRule:
        return this.processExactMatchRuleRows(rowData);
      case MenuItem.ClosestMatchRuleMall:
        return this.processClosestMatchRuleRows(tableType, rowData);
      case MenuItem.ClosestMatchRuleMerchant:
        return this.processClosestMatchRuleRows(tableType, rowData);
      case MenuItem.FieldReplacementMall:
      case MenuItem.FieldReplacementMerchant:
        return this.processFieldReplacementRows(tableType, rowData);
    }
  }
}
