import mime from "mime-types";

import { apiClient } from "../apiClient";
import { SUPPORTED_IMAGE_MIME } from "../constants";

export class UnsupportedFileError extends Error {
  fileType: string;

  constructor(fileType: string) {
    super("Unsupported file type");
    this.name = "UnsupportedFileError";
    this.fileType = fileType;
  }
}

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        resolve("");
      }
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsText(file);
  });
}

export function readFileAsDataUri(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        resolve(reader.result as string);
      },
      false
    );
    reader.addEventListener("error", () => {
      reject();
    });

    reader.readAsDataURL(file);
  });
}

export const getFileTypeWithDetection = (file: File): string => {
  // Ref: https://bugzilla.mozilla.org/show_bug.cgi?id=1424689
  // There is browser bug where file.type is empty string for file objects
  // returned by FileSystemFileEntry
  //
  // In our use case, files in folders does not have mime provided by browser
  // So we will lookup mime with file extension as fall back.
  return file.type || mime.lookup(file.name) || "";
};

export const uploadAsset = apiClient.createAsset.bind(apiClient);

export async function triggerFileSave(response: Response) {
  const blob = await response.blob();
  let filename: string | null = null;
  let contentDispositionHeader =
    response.headers.get("content-disposition") ??
    // fallback for GCS missing response header due to CORS
    new URL(response.url).searchParams.get("response-content-disposition");
  if (contentDispositionHeader != null) {
    contentDispositionHeader = decodeURIComponent(contentDispositionHeader);
    const filenameRegexMatch = contentDispositionHeader.match(/"([^"]+)"/);
    filename = filenameRegexMatch ? filenameRegexMatch[1] : null;
  }
  // fallback for when content-disposition header is stripped from response object due to CORS
  if (filename == null) {
    const pathname = decodeURIComponent(new URL(response.url).pathname);
    const paths = pathname.split("/");
    filename = paths[paths.length - 1];
  }
  await triggerFileSaveFromBlob(blob, filename);
}

export async function triggerFileSaveFromBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  const objUrl = window.URL.createObjectURL(blob);
  a.href = objUrl;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(objUrl);
}

export function disableDefaultFormSubmit(e: React.FormEvent<HTMLFormElement>) {
  e.preventDefault();
  e.stopPropagation();
}

async function findFiles(
  items: DataTransferItemList,
  supportedMIME: string[] | undefined,
  maxDepth: number,
  throwIfContainUnsupportedFile: boolean = false
) {
  const files: File[] = [];
  const unsupportedFilesInFolder: File[] = [];

  const addDirectory = async (item: FileSystemEntry | null, depth: number) => {
    if (depth > maxDepth || item === null) {
      return;
    }
    if (item.isDirectory) {
      const directoryReader = (item as FileSystemDirectoryEntry).createReader();
      let isRun = true;
      while (isRun) {
        const entries = await new Promise<FileSystemEntry[]>(resolve => {
          directoryReader.readEntries(
            entries => {
              if (!entries) {
                isRun = false;
              }
              resolve(entries);
            },
            () => resolve([])
          );
        });
        if (entries.length > 0) {
          await Promise.all(
            entries.map(entry => {
              return addDirectory(entry, depth + 1);
            })
          );
        } else {
          isRun = false;
        }
      }
    } else {
      const file = await new Promise<File>((res, rej) => {
        (item as FileSystemFileEntry).file(res, rej);
      });
      const fileType = getFileTypeWithDetection(file);
      if (supportedMIME === undefined || supportedMIME.includes(fileType)) {
        files.push(file);
      } else {
        unsupportedFilesInFolder.push(file);
      }
    }
  };

  const pendingItems: (FileSystemEntry | null)[] = [];
  Array.from(items).forEach(item => {
    const file = item.getAsFile();
    if (file !== null) {
      const fileType = getFileTypeWithDetection(file);
      const isDirectory =
        item.webkitGetAsEntry()?.isDirectory ?? file.size === 0;
      if (isDirectory) {
        pendingItems.push(item.webkitGetAsEntry());
      } else {
        if (supportedMIME === undefined || supportedMIME.includes(fileType)) {
          files.push(file);
        } else if (throwIfContainUnsupportedFile) {
          throw new UnsupportedFileError(fileType);
        }
      }
    }
  });

  await Promise.all(
    pendingItems.map(item => {
      return addDirectory(item, 1);
    })
  );
  if (files.length <= 0 && unsupportedFilesInFolder.length > 0) {
    throw new UnsupportedFileError(
      unsupportedFilesInFolder
        .map(file => getFileTypeWithDetection(file))
        .join(", ")
    );
  }
  return files;
}

export const createOnDrop =
  (
    onFiles: (files?: File[]) => any,
    supportedMIME: string[] | undefined = SUPPORTED_IMAGE_MIME,
    maxDepth = 10,
    onUnsupportedFileError?: (fileType: string) => void
  ) =>
  (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    const dataTransfer = e.dataTransfer;
    if (dataTransfer === null) {
      return;
    }
    if (dataTransfer.items) {
      findFiles(
        dataTransfer.items,
        supportedMIME,
        maxDepth,
        !!onUnsupportedFileError
      )
        .then(onFiles)
        .catch(e => {
          if (e instanceof UnsupportedFileError && onUnsupportedFileError) {
            onUnsupportedFileError(e.fileType);
          }
        });
    } else {
      const files = Array.from(dataTransfer.files);
      onFiles(files);
    }
  };

const FILE_INPUT_ID = "formx_file_input_id";

export function chooseFile(
  accept: string = SUPPORTED_IMAGE_MIME.join(","),
  isMultiple: boolean = false
): Promise<File[] | undefined> {
  return new Promise((resolve, _reject) => {
    const existingInputElement = document.getElementById(FILE_INPUT_ID);
    const isCreatingInputElement = existingInputElement === null;
    const inputElement = (existingInputElement ||
      document.createElement("input")) as HTMLInputElement;

    if (isCreatingInputElement) {
      inputElement.id = FILE_INPUT_ID;
      inputElement.type = "file";
      inputElement.style.display = "none";
      inputElement.multiple = isMultiple;
      document.body.appendChild(inputElement);
    } else {
      // Make sure the old values are cleared
      inputElement.value = "";
      inputElement.multiple = isMultiple;
    }

    inputElement.accept = accept;
    inputElement.onchange = (e: Event) => {
      if (e.target instanceof HTMLInputElement) {
        const { files } = e.target;

        if (files) {
          const fileArray: File[] = [];
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < files.length; i++) {
            fileArray.push(files[i]);
          }
          resolve(fileArray);
        } else {
          resolve(undefined);
        }
      }
    };

    inputElement.click();
  });
}
